import React from "react"
import { graphql, Link } from 'gatsby'
//import Img from 'gatsby-image'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Brand from '../components/brand/brand'
import WorksList from '../components/worksList/worksList'

export default ({ data }) => (
  <Layout>
    <SEO />
    <main className="TopPage">
      <section className="TopPage__section1">
        <Brand></Brand>
      </section>
      <section className="TopPage__section2">
        <div className="l-column">
          <WorksList></WorksList>
        </div>
      </section>
      <section className="TopPage__section3">
        <div className="l-column">
          <div className="BlogList">
            <div className="BlogList__body">
              <div className="BlogList__items">
                {data.allWordpressPost.edges.map(({ node }) => (
                  <article className="BlogList__item BlogList__item--top" key={node.id}>
                    <div className="BlogList__itemInner BlogList__itemInner--top">
                      <Link to={`/blog/post/${node.slug}/`}>
                      <div class="BlogList__itemInnerContainer">
                        <h3 className="BlogList__itemTitle">{node.title}</h3>
                        <p className="BlogList__itemText">
                          {`${node.excerpt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').slice(0, 70)}`}...  {/*htmlタグを取り除いた上で70文字までを表示*/}
                        </p>
                      </div>
                      </Link>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export const query = graphql`
query {
  imagePhoto: file(relativePath: {eq: "top/top_image_001.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  allWordpressPost(sort: {order: DESC, fields: date}, skip: 0, limit: 3) {
        edges {
          node {
            title
            id
            slug
            excerpt
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                alt_text
            }
          }
        }
    }
}
`
