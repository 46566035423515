import React, { useState } from 'react'
import WorksButton from '../atom/worksButton/worksButton'
import WorksDetail from '../worksDetail/worksDetail'
import { CSSTransition } from 'react-transition-group'
import './styles.scss'

export default () => {
    const works = [
        {
            name: "つるまい耳鼻咽喉科",
            description: "「つるまい耳鼻咽喉科」は、名古屋市中区千代田（鶴舞駅から徒歩3分、上前津駅から徒歩6分）にある耳鼻咽喉科です。鶴舞エリア、上前津エリアで「みみ・はな・のど」のかかりつけ医として健康増進および適切な医療サービスを提供できるよう努力して参ります。",
            src_pc: "hp_tsurumai-ent_pc.jpg",
            src_sp: "hp_tsurumai-ent_sp.jpg",
            url: "https://tsurumai-ent.com"
        },
        {
            name: "三輝工業株式会社",
            description: "三輝工業は、愛知県知多郡東浦町にあるプラスチック射出成形メーカーです。豊富なノウハウの蓄積を生かした確かな品質の製品を創り出すことで社会貢献の自負を持ち、事上錬磨を続ける人間集団を目指します。",
            src_pc: "hp_sanki-p_pc.jpg",
            src_sp: "hp_sanki-p_sp.jpg",
            url: "http://sanki-plastics.co.jp"
        },
        {
            name: "城北クリニック",
            description: "名古屋市北区の内科、透析科、城北クリニックは、社会復帰された患者様も安心して透析が受けられるように、月曜日から土曜日の23時まで診療しております。臨時透析にも対応いたします。地下鉄名城線 黒川駅 4番出口より徒歩1分ですので、どこからお越しいただいても便利です。",
            src_pc: "hp_johoku_pc.jpg",
            src_sp: "hp_johoku_sp.jpg",
            url: "http://johokuclinic.la.coocan.jp"
        },
        {
            name: "株式会社CB教育",
            description: "クラスベネッセの教室を運営する「株式会社 CB教育」のホームページ。クラスベネッセ（Class Benesse）とは、「進研ゼミ」の小・中学生向け個人別指導教室です。",
            src_pc: "hp_cb_pc.jpg",
            src_sp: "hp_cb_sp.jpg",
            url: "https://cbkyouiku.co.jp"
        },
        {
            name: "日本堂",
            description: "日本堂は、70年続く愛知県瀬戸市の宝石店。風情ある陶器の町の中心街にあります。宝石、時計、眼鏡 は【大事にしたいお道具】です。日本堂スタッフはお客様の気持ちに寄り添って、お道具選びのアドバイスをします。",
            src_pc: "hp_nihondo_pc.jpg",
            src_sp: "hp_nihondo_sp.jpg",
            url: "https://nihondo-japan.co.jp"
        },
        {
            name: "広野製作所",
            description: "広野製作所は、愛知県知多郡東浦町にあるプラスチック射出成形会社です。1986年の創業から地域に密着した企業で、自動車内装部品から印鑑ケースまで多品種のプラスチック製品を製造しています。",
            src_pc: "hp_hirono_pc.jpg",
            src_sp: "hp_hirono_sp.jpg",
            url: "https://hirono-plastics.co.jp"
        },
        {
            name: "大島ランドリー",
            description: "大島ランドリーは、愛知県刈谷市にある高級クリーニング店です。最初に細かな汚れをチェックし、1箇所1箇所、丹念にそれを落とします。次に色や素材により18種類の中から適切なクリーニングを施します。最後に機械と手作業の両方で丁寧に仕上げます。他店で落ちなかったシミはもちろん、どんな汚れもキレイに落とします。",
            src_pc: "hp_ohshima_pc.jpg",
            src_sp: "hp_ohshima_sp.jpg",
            url: "https://ohshima-ldry.co.jp"
        },
        {
            name: "ヤミーハワイアンバーベキュー通販",
            description: "ハワイ有名店ヤミーのオフィシャル通販サイト。骨付きカルビ、ガーリックシュリンプ、ロコモコなどのハワイ飯が、通販で自宅にお取り寄せできます。",
            src_pc: "hp_yummy_pc.jpg",
            src_sp: "hp_yummy_sp.jpg",
            url: "https://yummy-web.com"
        },
        {
            name: "有限会社サンキ",
            description: "サンキは、プラスチックの着色加工専門会社です。今では数少なくなってきた着色加工を専門に行っております。確かな技術力をお値打ち価格で。サンキの「採用情報」ページです。小ロット、小口にも対応しています。工場は愛知県名古屋市南区にあります。",
            src_pc: "hp_sanki_pc.jpg",
            src_sp: "hp_sanki_sp.jpg",
            url: "https://sanki-color.co.jp"
        },
        {
            name: "RSビル",
            description: "RSビルは、伏見駅徒歩約5分に位置するオフィスビルです。名古屋駅、栄という2大都心に隣接した、好立地の伏見でビジネスチャンスをつかみませんか。",
            src_pc: "hp_rs_pc.jpg",
            src_sp: "hp_rs_sp.jpg",
            url: "https://rs-bld.com"
        },
        {
            name: "八達不動産鑑定事務所",
            description: "八達不動産では不動産の本当の価値を判定し、 さらに不動産の取得・利用・売却その他これらに関連する事柄についてアドバイスや企画提案を行っています。不動産鑑定評価、調査報告書作成、広大地判定、不動産コンサルティング、全国対応可能。",
            src_pc: "hp_8tatu_pc.jpg",
            src_sp: "hp_8tatu_sp.jpg",
            url: "https://8tatu.com"
        },
        {
            name: "中央台診療所",
            description: "春日井市中央台に密着した診療所です。皆さまの「かかりつけ医」としてどんなことでもご相談ください。医療法人中央会。",
            src_pc: "hp_chuodai_pc.jpg",
            src_sp: "hp_chuodai_sp.jpg",
            url: "https://chuodai.net"
        },
        {
            name: "ヤミーハワイアンバーベキュー",
            description: "ハワイアンバーベキューヤミー（Yummy）を名古屋でエンジョイ！栄本店・ささしまグローバルゲート店・パルコ店＆ハワイ17店舗展開。",
            src_pc: "hp_yummybbq_pc.jpg",
            src_sp: "hp_yummybbq_sp.jpg",
            url: "https://yummy-bbq.com"
        },
        {
            name: "シュピール・オアゼ",
            description: "シュピール・オアゼは、愛知県春日井市にある小さいお子様のためのおもちゃ屋さんです。『シュピール・オアゼ』とは、ドイツ語で「あそびのオアシス」という意味。世界中から選りすぐりのあそび道具を取り揃えて、あそびと教育から子どもの健やかな成長をお手伝いしています。素材や色、形など、こだわりいっぱいの「本物」のおもちゃを販売しています。いつでもここに来れば、新しくて楽しい遊びが見つかることでしょう。",
            src_pc: "hp_spiel-oase_pc.jpg",
            src_sp: "hp_spiel-oase_sp.jpg",
            url: "https://spiel-oase.com"
        },
		{
            name: "栗原工業株式会社",
            description: "栗原工業株式会社は茨城県守谷市にあるシートメタルの会社です。1960年以来、アルミニウム建材を中心に事業を展開しています。長年培った技術による設計段階よりの提案力、自社工場を持つことで常に目の届く品質管理、納期にも臨機応変に対応できるような生産体制を持ち、「お客様第一」を掲げています。",
            src_pc: "hp_kurihara_pc.jpg",
            src_sp: "hp_kurihara_sp.jpg",
            url: "https://kuriharakogyo.net"
        },
		{
            name: "名古屋大学大学院 医学系研究科 生物化学講座 分子生物学 島田研究室",
            description: "名古屋大学大学院 医学系研究科 生物化学講座 分子生物学 島田研究室では、生命現象の根幹である遺伝情報の継承および発現制御機構の解明から、がんにおける治療標的同定と治療戦略開発に至るまで統合的な研究を行っています。分子の病態機能解明に基づき、新たな治療法を確立し、健康寿命を伸ばすという大きな夢に挑戦したいと考えています。大学院生も随時募集しております。",
            src_pc: "hp_nagoya-shimada_pc.jpg",
            src_sp: "hp_nagoya-shimada_sp.jpg",
            url: "https://www.med.nagoya-u.ac.jp/biochem/index.html"
        },
    ]

    const [showButton, setShowButton] = useState(true)
    const [detail, setDetail] = useState(
        {
            show: false,
            target: {},
        }
    )
    const closeDetail = () => setDetail({ ...detail, show: false })

    return (
        <>
            <div className="WorksList">
                <CSSTransition
                    in={detail.show}
                    timeout={300}
                    classNames="detail"
                    unmountOnExit
                    onEnter={() => setShowButton(false)}
                    onExited={() => setShowButton(true)}
                >
                    <WorksDetail detail={detail} closeDetail={closeDetail}></WorksDetail>
                </CSSTransition>
                <div className="WorksList__items">
                    {/* RSビル */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[9], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <path d="M95,47.7c0,3.7,1.6,6.9,5.4,11c4.4,4.7,10.2,12.2,10.2,22.2c0,16.2-13.2,29.5-29.5,29.5
			c-16.2,0-29.5-13.2-29.5-29.5c0-10.1,5.8-17.5,10.2-22.2c3.8-4.1,5.4-7.3,5.4-11c0-7.6-6.2-13.8-13.8-13.8H27
			c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h26.5c4.9,0,8.9,4,8.9,8.9c0,2.3-1.2,4.6-4.1,7.7C53.4,60.7,46.8,69.2,46.8,81
			c0,18.9,15.4,34.3,34.3,34.3s34.3-15.4,34.3-34.3c0-11.8-6.6-20.2-11.5-25.6c-2.9-3.1-4.1-5.4-4.1-7.7c0-4.9,4-8.9,8.9-8.9
			c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4C101.1,33.9,95,40.1,95,47.7z" />
                                            <path d="M85.8,47.7c0,7.9,4.5,13.6,7.8,17.3c3.6,3.9,7.8,9.2,7.8,16c0,11.2-9.1,20.3-20.3,20.3
			c-11.2,0-20.3-9.1-20.3-20.3c0-6.8,4.2-12.1,7.8-16c3.4-3.6,7.8-9.3,7.8-17.3c0-12.7-10.3-23-23-23H27c-1.3,0-2.4,1.1-2.4,2.4
			c0,1.3,1.1,2.4,2.4,2.4h26.5c10,0,18.1,8.1,18.1,18.1c0,4.8-2,9.1-6.5,14C60.8,66.2,56,72.5,56,81c0,13.9,11.3,25.1,25.1,25.1
			c13.9,0,25.1-11.3,25.1-25.1c0-8.5-4.8-14.8-9.1-19.3c-4.5-4.9-6.5-9.2-6.5-14c0-10,8.1-18.1,18.1-18.1c1.3,0,2.4-1.1,2.4-2.4
			c0-1.3-1.1-2.4-2.4-2.4C96.1,24.7,85.8,35,85.8,47.7z" />
                                            <path d="M24.6,47.3v65.5c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4V47.3c0-1.3-1.1-2.4-2.4-2.4
			C25.7,44.9,24.6,46,24.6,47.3z" />
                                            <path d="M34,47.3v65.5c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4V47.3c0-1.3-1.1-2.4-2.4-2.4
			C35.1,44.9,34,46,34,47.3z" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* ヤミーBBQ */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[12], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <g>
                                                <path d="M48.1,46.2v19.1c0,2.5-1.4,2.9-3,2.9c-1.6,0-3-0.3-3-2.9V46.2h-8v18.3c0,5.4,3.4,10.8,11,10.8
				c7.6,0,11-5.4,11-10.8V46.2H48.1z" />
                                                <path d="M75.5,75.3V54.6c0-2.4,1.2-2.4,1.9-2.4c0.7,0,1.9,0,1.9,2.4v20.7h7V55.6c0-4.5-2.8-9.4-8.8-9.4
				c-2.2,0-4.6,1.4-5.4,3c-0.7-1.6-3.1-3-5.4-3c-6.1,0-8.8,4.9-8.8,9.4v19.7h7V54.6c0-2.4,1.2-2.4,1.9-2.4c0.7,0,1.9,0,1.9,2.4v20.7
				H75.5z" />
                                                <path d="M105.6,75.3V54.6c0-2.4,1.2-2.4,1.9-2.4c0.7,0,1.9,0,1.9,2.4v20.7h7V55.6c0-4.5-2.8-9.4-8.8-9.4
				c-2.2,0-4.6,1.4-5.4,3c-0.7-1.6-3.1-3-5.3-3c-6.1,0-8.8,4.9-8.8,9.4v19.7h7V54.6c0-2.4,1.2-2.4,1.9-2.4c0.7,0,1.9,0,1.9,2.4v20.7
				H105.6z" />
                                                <path d="M132,46.2v19.1c0,2.5-1.4,2.9-3,2.9c-1.6,0-3-0.3-3-2.9V46.2h-8v18.3c0,5.4,3.4,10.8,11,10.8
				c1.1,0,2.1-0.1,3-0.3v13.9c0,2.5-2.2,3.7-3.7,3.7c-2.5,0-3.5-0.9-4.8-3.7c-1.2-2.9-1.4-9.9-1.4-9.9v-2H21.1c0,2.9,3.1,5.3,7,5.3
				c3,0,5.5-1.4,6.5-3.3h72.4c3.8,0.2,7.9,4.4,10.1,8.5c1.9,3.5,5.3,9.4,11.8,9.4c7.6,0,11-5.4,11-10.8V64.5V46.2H132z" />
                                                <path d="M72,44.1v-2H34.4l0,0h-3.4l0,0h-6v2h5.1l-8.7,18.8l-7.5-18.8h6.8v-2h-7.6l0,0H5l0,0H2.9l0,2h2.9l11.3,28.1
				l-6.9,14.9H0C0,90.9,2.9,94,6.4,94c2.8,0,4.8-1.9,6-4.5h0l21-45.4H72z" />
                                            </g>
                                            <g>
                                                <path d="M29.2,90.9h-2.7c-0.1,2.2-0.1,3.5,0,4c0,0.1,0.1,0.3,0.1,0.5c-0.5,0.3-1,0.4-1.5,0.4c-0.6,0-0.9-0.2-1-0.6
				c-0.1-0.3-0.1-0.6,0-1.1l0.1-1.1c0.2-3.7,0.2-6.1,0-7c-0.1-0.3-0.2-0.6-0.4-0.8c0.6-0.4,1.2-0.6,1.8-0.6c0.5,0,0.8,0.2,0.9,0.6
				c0.1,0.3,0,1.7-0.1,4.1h2.7c0-1.7,0-2.8-0.1-3.3c-0.1-0.3-0.2-0.6-0.4-0.8c0.6-0.4,1.2-0.6,1.8-0.6c0.5,0,0.8,0.2,0.9,0.6
				c0,0.3,0,0.8,0,1.8l-0.1,5.8c0,1.1,0,1.8,0.1,2.1c0,0.1,0.1,0.3,0.2,0.5c-0.5,0.3-1,0.4-1.5,0.4c-0.6,0-0.9-0.2-1-0.6
				c-0.1-0.3-0.1-0.6,0-1.1l0.1-1.1C29.2,92.5,29.2,91.8,29.2,90.9z" />
                                                <path d="M36.4,92.3c-0.5,1.1-0.9,1.7-1.1,1.7c-0.3,0-0.5-0.3-0.6-0.9c-0.2-0.9-0.2-2,0-3.2c0.2-1.2,0.6-1.7,1.1-1.7
				c0.5,0,1,0.3,1.7,1C37.3,90.1,36.9,91.1,36.4,92.3z M37.6,94.3c0.2,1,0.7,1.5,1.6,1.5c0.5,0,0.9-0.2,1.2-0.5
				c-0.3-0.5-0.5-1-0.6-1.6c-0.2-0.9-0.2-2-0.2-3.4c0-1.4,0.1-2.5,0.3-3.1c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.4
				c-0.5-0.3-1.1-0.5-1.7-0.5c-1.3,0-2.2,0.7-2.9,2.2c-0.6,1.5-0.8,3-0.5,4.5c0.3,1.6,1,2.4,2.1,2.4c1.1,0,2.1-1.2,2.9-3.5
				C37.5,93.2,37.5,93.8,37.6,94.3z" />
                                                <path d="M42.3,95.9c0-0.5-0.1-1.2-0.3-2.1c-0.1-0.6-0.2-1.3-0.4-2c-0.2-0.8-0.3-1.5-0.5-2.2
				c-0.2-0.8-0.5-1.6-0.9-2.1c0.6-0.4,1-0.5,1.4-0.5c0.6,0,1,0.5,1.2,1.4l0.2,1c0.1,0.7,0.4,1.8,0.7,3.2c1-2,1.4-3.7,1.2-4.9
				c0-0.1,0-0.1,0-0.2c0.4-0.2,0.8-0.3,1.2-0.3c0.6,0,0.9,0.5,1.1,1.5c0.1,0.4,0.2,0.9,0.2,1.5c0.1,0.7,0.3,1.8,0.5,3.3
				c0.9-2.5,1.3-4.3,1.1-5.3c-0.1-0.4-0.2-0.7-0.4-0.9c0.5-0.2,1-0.4,1.4-0.4c0.5,0,0.7,0.2,0.8,0.6c0.1,0.3-0.2,1.5-0.8,3.5
				c-0.6,2-1.1,3.3-1.5,3.9c-0.4,0.6-1.1,1-2,1.1c-0.1-0.4-0.1-0.6-0.1-0.8c-0.1-0.3-0.2-1-0.4-2.2c-0.1-0.4-0.2-0.8-0.2-1.2
				c-0.1-0.4-0.1-0.7-0.2-1c-0.3,1.7-0.8,2.9-1.2,3.8C44,95.3,43.3,95.8,42.3,95.9z" />
                                                <path d="M55.2,92.3c-0.5,1.1-0.9,1.7-1.1,1.7c-0.3,0-0.5-0.3-0.6-0.9c-0.2-0.9-0.2-2,0-3.2c0.2-1.2,0.6-1.7,1.1-1.7
				c0.5,0,1,0.3,1.7,1C56,90.1,55.7,91.1,55.2,92.3z M56.4,94.3c0.2,1,0.7,1.5,1.6,1.5c0.5,0,0.9-0.2,1.2-0.5
				c-0.3-0.5-0.5-1-0.6-1.6c-0.2-0.9-0.2-2-0.2-3.4c0-1.4,0.1-2.5,0.3-3.1c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.4
				c-0.5-0.3-1.1-0.5-1.7-0.5c-1.3,0-2.2,0.7-2.9,2.2c-0.6,1.5-0.8,3-0.5,4.5c0.3,1.6,1,2.4,2.1,2.4c1.1,0,2.1-1.2,2.9-3.5
				C56.3,93.2,56.3,93.8,56.4,94.3z" />
                                                <path d="M59.8,87.2c0.5-0.2,1.1-0.3,1.6-0.3c0.7,0,1,0.3,1.1,0.8c0,0.2,0,0.5,0,0.8c-0.2,2.8-0.2,4.7,0,5.7
				c0.1,0.5,0.3,0.9,0.6,1.2c-0.4,0.3-0.9,0.5-1.3,0.5c-0.8,0-1.3-0.4-1.5-1.2c-0.1-0.7-0.1-1.8-0.1-3.3c0.1-1.5,0.1-2.7-0.1-3.3
				C60.1,87.7,60,87.5,59.8,87.2z M62.4,85.5c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-0.9-0.2-0.9-0.5c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5
				c0-0.1-0.1-0.2-0.1-0.3c0.5-0.2,1-0.3,1.3-0.3c0.6,0,1,0.2,1.1,0.7C62.5,84.8,62.5,85.1,62.4,85.5z" />
                                                <path d="M63.9,87.2c0.5-0.2,1.1-0.3,1.6-0.3c0.6,0,1,0.3,1.1,0.8c0,0.2,0,0.5,0,0.8c-0.2,2.8-0.2,4.7,0,5.7
				c0.1,0.5,0.3,0.9,0.6,1.2c-0.4,0.3-0.9,0.5-1.3,0.5c-0.8,0-1.3-0.4-1.5-1.2c-0.1-0.7-0.1-1.8-0.1-3.3c0.1-1.5,0.1-2.7-0.1-3.3
				C64.1,87.7,64,87.5,63.9,87.2z M66.4,85.5c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-0.9-0.2-1-0.5c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5
				c0-0.1-0.1-0.2-0.1-0.3c0.5-0.2,1-0.3,1.3-0.3c0.6,0,1,0.2,1.1,0.7C66.6,84.8,66.5,85.1,66.4,85.5z" />
                                                <path d="M71.7,92.3c-0.5,1.1-0.9,1.7-1.1,1.7c-0.3,0-0.5-0.3-0.6-0.9c-0.2-0.9-0.2-2,0-3.2c0.2-1.2,0.6-1.7,1.1-1.7
				c0.5,0,1,0.3,1.7,1C72.6,90.1,72.2,91.1,71.7,92.3z M72.9,94.3c0.2,1,0.7,1.5,1.6,1.5c0.5,0,0.9-0.2,1.2-0.5
				c-0.3-0.5-0.5-1-0.6-1.6c-0.2-0.9-0.2-2-0.2-3.4c0-1.4,0.1-2.5,0.3-3.1c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.7,0.1-0.9,0.4
				c-0.5-0.3-1.1-0.5-1.7-0.5c-1.3,0-2.2,0.7-2.9,2.2c-0.6,1.5-0.8,3-0.5,4.5c0.3,1.6,1,2.4,2.1,2.4c1.1,0,2.1-1.2,2.9-3.5
				C72.8,93.2,72.8,93.8,72.9,94.3z" />
                                                <path d="M78.8,90.3c0.7-2.3,1.7-3.5,2.9-3.5c0.9,0,1.4,0.5,1.6,1.4c0.1,0.7,0.1,1.6,0.1,3c-0.1,1.3-0.1,2.3,0.1,3
				c0.1,0.5,0.3,1,0.6,1.3c-0.4,0.3-0.9,0.5-1.4,0.5c-0.8,0-1.3-0.4-1.4-1.2c-0.1-0.6-0.2-1.3-0.1-2.1c0-2.1,0-3.3,0-3.5
				c0-0.2-0.1-0.4-0.3-0.4c-0.3,0-0.7,0.8-1.4,2.5c-0.6,1.7-0.9,3-0.7,3.9c0,0.2,0.1,0.4,0.2,0.6c-0.4,0.1-0.8,0.2-1.2,0.2
				c-0.7,0-1.1-0.3-1.2-0.8c0-0.3-0.1-0.6,0-1l0.1-1c0.1-2.6,0.1-4.3,0-5.1c0-0.2-0.1-0.5-0.2-0.7c0.5-0.2,1-0.3,1.4-0.3
				c0.7,0,1,0.3,1.1,0.8C78.9,88,78.9,88.9,78.8,90.3z" />
                                                <path d="M89.3,85.6c0.7,0,1.1,0.4,1.2,1.2c0.3,1.5-0.5,2.4-2.3,2.7l0.1-3.7C88.7,85.7,89,85.6,89.3,85.6z M92.7,86.4
				c-0.2-1.3-1.3-1.9-3.3-1.9c-1.6,0-2.9,0.3-3.8,0.9c0.2,0.2,0.3,0.5,0.4,0.8c0.2,1.2,0.2,4-0.1,8.4c0,0.3,0,0.5,0,0.7
				c0.1,0.4,0.4,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.6,0c0.3,0,0.5,0,0.6,0c1.4,0,2.5-0.4,3.3-1.2
				c0.8-0.8,1.1-1.7,0.9-2.6c-0.2-1.1-1.1-1.9-2.7-2.4C92.2,88.8,92.9,87.7,92.7,86.4z M88.3,90.7c0.3,0,0.7-0.1,1.1-0.3
				c0.9,0.3,1.4,0.9,1.6,1.9c0.1,0.6,0,1.2-0.2,1.7c-0.2,0.5-0.6,0.7-1.2,0.7c-0.4,0-0.8-0.1-1.3-0.2L88.3,90.7z" />
                                                <path d="M97.7,85.6c0.7,0,1.1,0.4,1.2,1.2c0.3,1.5-0.5,2.4-2.3,2.7l0.1-3.7C97.1,85.7,97.4,85.6,97.7,85.6z
				 M101.1,86.4c-0.2-1.3-1.3-1.9-3.3-1.9c-1.7,0-2.9,0.3-3.8,0.9c0.2,0.2,0.3,0.5,0.4,0.8c0.2,1.2,0.2,4-0.1,8.4c0,0.3,0,0.5,0,0.7
				c0.1,0.4,0.4,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.6,0c0.3,0,0.5,0,0.6,0c1.4,0,2.5-0.4,3.3-1.2
				c0.8-0.8,1.1-1.7,1-2.6c-0.2-1.1-1.1-1.9-2.7-2.4C100.6,88.8,101.4,87.7,101.1,86.4z M96.7,90.7c0.3,0,0.7-0.1,1.1-0.3
				c0.9,0.3,1.4,0.9,1.6,1.9c0.1,0.6,0.1,1.2-0.2,1.7c-0.3,0.5-0.6,0.7-1.2,0.7c-0.3,0-0.8-0.1-1.3-0.2L96.7,90.7z" />
                                                <path d="M105,92.9c-0.3-1.4-0.3-3-0.1-4.7c0.2-1.7,0.7-2.6,1.4-2.6c0.6,0,1.1,0.6,1.3,1.9c0.3,1.4,0.3,3,0.1,4.7
				c-0.2,1.7-0.7,2.6-1.5,2.6C105.7,94.8,105.2,94.2,105,92.9z M109.9,92.2c0.5-1.5,0.6-2.9,0.4-4.3c-0.4-2.2-1.5-3.3-3.3-3.3
				c-1.5,0-2.8,0.9-3.6,2.7c-0.9,1.8-1.2,3.6-0.8,5.5c0.4,2.2,1.5,3.3,3.3,3.3c0.2,0,0.4,0,0.7-0.1c1.9,1.3,3.1,2,3.8,2
				c0.6,0,1.1-0.6,1.6-1.7c-0.2,0-0.5,0-0.7,0c-1.2,0-2.4-0.3-3.5-0.8C108.6,94.7,109.4,93.7,109.9,92.2z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* ヤミー通販 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[7], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <path d="M70,135.9c-36.4,0-65.9-29.6-65.9-65.9S33.6,4.1,70,4.1c36.4,0,65.9,29.6,65.9,65.9S106.4,135.9,70,135.9z
		 M70,7.3C35.4,7.3,7.3,35.4,7.3,70c0,34.6,28.1,62.7,62.7,62.7s62.7-28.1,62.7-62.7C132.7,35.4,104.6,7.3,70,7.3z" />
                                            <g>
                                                <path d="M58,25.3c-0.3,0-0.5,0.1-0.7,0.3C57.1,25.8,57,26,57,26.3h2c0-0.3-0.1-0.5-0.3-0.7
			C58.5,25.4,58.3,25.3,58,25.3z" />
                                                <path d="M70.1,25.3c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,1s0.1,0.8,0.3,1c0.2,0.2,0.4,0.4,0.8,0.4
			c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.6,0.3-1s-0.1-0.8-0.3-1C70.7,25.5,70.5,25.3,70.1,25.3z" />
                                                <path d="M70,13.3c-19,0-35.9,9.4-46.2,23.8h92.4C105.9,22.6,89,13.3,70,13.3z M57.4,28c0.2,0.1,0.4,0.2,0.6,0.2
			c0.3,0,0.5-0.1,0.6-0.2s0.2-0.2,0.2-0.3H60c0,0.3-0.2,0.5-0.4,0.8c-0.4,0.4-0.9,0.6-1.7,0.6c-0.6,0-1.1-0.2-1.6-0.5
			c-0.5-0.4-0.7-1-0.7-1.8s0.2-1.3,0.6-1.8c0.4-0.4,0.9-0.6,1.6-0.6c0.4,0,0.7,0.1,1,0.2s0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.4,0.9
			c0,0.2,0,0.5,0,0.9h-3C57,27.5,57.1,27.8,57.4,28z M64.8,28.9h-1.2v-2.7c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.3-0.3-0.6-0.3
			c-0.4,0-0.7,0.2-0.8,0.5C62,26.1,62,26.3,62,26.6V29h-1.2v-4.4h1.1v0.6c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.2,0.5-0.3,0.9-0.3
			c0.5,0,0.8,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1.2L64.8,28.9L64.8,28.9z M67,29.4c0,0.4-0.1,0.8-0.3,1c-0.2,0.2-0.5,0.3-1,0.3
			c0,0-0.1,0-0.2,0s-0.2,0-0.3,0v-0.9c0.1,0,0.1,0,0.1,0h0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-4.9H67V29.4z M67,24
			h-1.2v-1.1H67V24z M71.8,28.4c-0.4,0.5-0.9,0.7-1.7,0.7c-0.8,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1-0.6-1.7c0-0.6,0.2-1.2,0.6-1.7
			s0.9-0.7,1.7-0.7c0.8,0,1.3,0.2,1.7,0.7c0.4,0.5,0.6,1,0.6,1.7S72.2,27.9,71.8,28.4z M75.6,28.9c-0.3,0.8-0.5,1.4-0.7,1.6
			s-0.5,0.3-1,0.3c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0v-0.9h0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.3
			c0.1-0.1,0.1-0.2,0.1-0.3l-1.6-4.6H74l1,3.3l0.9-3.3h1.2L75.6,28.9z M80.5,28.9h-1.2v-1.2h1.2V28.9z M81.6,25.5
			c-0.1,0.1-0.3,0.3-0.6,0.6l-0.3,0.2c-0.2,0.1-0.3,0.3-0.3,0.4s-0.1,0.3-0.1,0.5h-1.1c0-0.5,0.1-0.8,0.1-0.9
			c0.1-0.2,0.2-0.4,0.5-0.6l0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.6s-0.3-0.3-0.7-0.3
			c-0.3,0-0.5,0.1-0.7,0.3c0.1,0.2,0.1,0.4,0.1,0.6h-1.2c0-0.8,0.3-1.3,0.8-1.6c0.3-0.2,0.7-0.3,1.2-0.3c0.6,0,1.1,0.1,1.5,0.4
			s0.6,0.7,0.6,1.3C81.9,25,81.8,25.3,81.6,25.5z" />
                                            </g>
                                            <g>
                                                <rect x="73.9" y="105.4" width="1.5" height="0.7" />
                                                <path d="M86,106.2c-0.1,2.5-0.3,3.8-0.8,5c0.9-0.5,1.5-1,2-1.7C86.6,108.6,86.3,107.8,86,106.2L86,106.2z" />
                                                <rect x="76.4" y={107} width="1.6" height="0.7" />
                                                <path d="M77,103.4h-2.2c0.4,0.3,0.5,0.3,1.1,0.8C76.7,103.7,76.7,103.7,77,103.4z" />
                                                <path d="M19.7,96.2c9.5,18.1,28.4,30.5,50.3,30.5s40.9-12.4,50.3-30.5H19.7z M91.4,103.2c0.2,0,0.5,0.1,1,0.1h1.1V103
			c0-0.3,0-0.5-0.1-0.8h1.3c0,0.2-0.1,0.4-0.1,0.8v0.3h1c0.5,0,0.7,0,1-0.1v1.2c-0.2,0-0.4-0.1-0.9-0.1h-3.4c-0.4,0-0.6,0-0.9,0.1
			V103.2z M93.7,105.8l-1.1,0.3c-0.2-0.6-0.3-1-0.5-1.5l1-0.3C93.4,104.8,93.5,105.2,93.7,105.8z M81.8,102.6h1.5c0.5,0,0.6,0,0.9,0
			c0,0.3,0,0.4,0,0.9v5.1c0,0.5,0,0.7,0,0.9c-0.3,0-0.4,0-0.8,0h-1.9c-0.5,0-0.6,0-0.8,0c0-0.4,0-0.7,0-1.1v-4.9c0-0.4,0-0.7,0-1
			C81.1,102.6,81.4,102.6,81.8,102.6z M72.6,102.4c0.3,0,0.7,0.1,1.3,0.1h3.5c0.7,0,1,0,1.2-0.1l0.5,0.7c-0.2,0.1-0.2,0.2-0.5,0.4
			c-0.4,0.4-1,0.7-1.4,1h0.9c0.5,0,0.8,0,1.1-0.1c0,0.3,0,0.6,0,1.2v3.3c0,0.6,0,0.8-0.2,1s-0.5,0.2-1.1,0.2c-0.1,0-0.3,0-0.5,0
			c0-0.4-0.1-0.7-0.2-1c0.3,0,0.5,0.1,0.7,0.1s0.2,0,0.2-0.3v-0.4h-1.6v0.5c0,0.6,0,0.9,0.1,1.2h-1.2c0-0.3,0.1-0.6,0.1-1.2v-0.5H74
			v0.5c0,0.6,0,0.9,0.1,1.2h-1.2c0-0.3,0.1-0.6,0.1-1.2v-3.4c0-0.6,0-0.9,0-1.3c0.3,0,0.6,0.1,1.2,0.1H75c-0.6-0.4-0.6-0.4-0.9-0.6
			l0.8-0.5h-1c-0.5,0-0.8,0-1.1,0.1v-1H72.6z M70.5,102.3c0.8,0.7,1.3,1.3,1.9,1.9l-0.9,0.9c-0.6-0.9-1.1-1.4-1.8-2L70.5,102.3z
			 M51.4,102.6c2.1,0.2,3.4,0.5,5.8,1.2l-0.4,1.3c-1.8-0.6-4-1.1-5.9-1.2L51.4,102.6z M51.2,105.6c1.7,0.2,3.4,0.6,5.6,1.2l-0.4,1.3
			c-0.8-0.3-1.6-0.6-3-0.9c-0.9-0.2-1.6-0.3-2.7-0.5L51.2,105.6z M48.6,104.9c-0.3,0.7-0.8,1.6-1.3,2.2c-0.4,0.4-0.7,0.7-1.3,1.2
			c-0.4-0.4-0.5-0.5-1.1-0.8c0.7-0.4,1-0.7,1.4-1.2s0.6-0.8,0.9-1.4c-0.1,0-0.2,0-0.3,0.1l-3.5,0.6l0.9,4.9c0.1,0.6,0.2,0.8,0.3,1.1
			l-1.5,0.3c0-0.3-0.1-0.7-0.1-1.1l-0.9-4.9l-1.2,0.2c-0.6,0.1-0.8,0.2-1.2,0.3l-0.2-1.4c0.5,0,0.8,0,1.2-0.1l1.1-0.2l-0.2-1.1
			c-0.1-0.6-0.1-0.7-0.2-1l1.4-0.2c0,0.3,0,0.5,0.1,0.9l0.2,1.1l4.3-0.7c0.3-0.1,0.5-0.1,0.7-0.2l0.8,0.7
			C48.8,104.4,48.8,104.5,48.6,104.9z M57.1,111.7c-1.1-0.5-2.1-0.8-3.8-1.2c-1.3-0.3-2.2-0.5-3.4-0.6l0.5-1.2
			c2.6,0.3,4.6,0.8,7.1,1.7L57.1,111.7z M68.5,107.8c-0.4-0.1-0.5-0.1-1.8-0.1H61c-1.2,0-1.3,0-1.8,0.1v-1.5
			c0.5,0.1,0.7,0.1,1.7,0.1h5.7c1.1,0,1.3,0,1.7-0.1v1.5H68.5z M79.4,111.8c-1.4,0.1-2.5,0.1-4.3,0.1c-1.1,0-1.9-0.1-2.4-0.3
			c-0.5-0.2-0.8-0.4-1.2-0.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.4,0.4-1.2,1l-0.6-1.2c0.4-0.2,0.9-0.6,1.4-1v-2.3h-0.5
			c-0.3,0-0.4,0-0.7,0.1v-1.2c0.3,0,0.5,0.1,0.8,0.1h0.8c0.3,0,0.6,0,0.8-0.1c0,0.3,0,0.6,0,1.2v2.3c0.2,0.3,0.3,0.4,0.5,0.6
			c0.2,0.1,0.4,0.2,0.5,0.3c0.5,0.1,1.2,0.2,2.1,0.2c1.8,0,2.3,0,4.5-0.3C79.5,110.9,79.4,111.3,79.4,111.8z M81.2,112.1
			c-0.3-0.3-0.5-0.5-1-0.7c0.6-0.6,0.8-1,1.1-1.8l1,0.5C82,111,81.8,111.4,81.2,112.1z M89.9,112.1c-0.9-0.6-1.5-1-2-1.6
			c-0.6,0.7-1,1.1-2,1.7c-0.2-0.4-0.5-0.7-0.7-0.9c-0.2,0.3-0.2,0.5-0.5,0.9c-0.3-0.3-0.5-0.5-0.8-0.7c-0.2,0.1-0.2,0.2-0.4,0.4
			c-0.4-0.7-0.6-1-0.9-1.8l0.9-0.4c0.2,0.5,0.4,0.8,0.6,1.2c0.6-1.1,0.8-2.7,0.8-5.5c0-0.4,0-0.8,0-1.3c0-0.1,0-0.1,0-0.2
			c0-0.5,0-0.9,0-1.2c0.4,0,0.8,0.1,1.2,0.1h3c0.5,0,0.9,0,1.2-0.1v1.2c-0.3,0-0.7-0.1-1.2-0.1H86v1.4h2.7c0.4,0,0.7,0,0.9-0.1
			l0.5,0.6c-0.1,0.3-0.2,0.4-0.3,1c-0.3,1.2-0.7,1.9-1.2,2.7c0.5,0.6,1.2,1,1.9,1.4C90.2,111.3,90,111.6,89.9,112.1z M95.1,112.1
			v-0.6H93v0.6h-1.2c0-0.3,0-0.6,0-1v-2.3c0-0.4,0-0.6,0-0.9c0.2,0,0.5,0,0.9,0h2.8c0.4,0,0.6,0,0.8,0c0,0.2,0,0.3,0,0.8v2.6
			c0,0.4,0,0.6,0,0.8H95.1z M96.7,107.3c-0.2,0-0.5-0.1-0.9-0.1h-3.6c-0.5,0-0.8,0-1.1,0.1v-1.2c0.3,0,0.5,0.1,1.1,0.1h2.1
			c0.3-0.6,0.4-1.1,0.6-1.8l1.1,0.3c-0.2,0.5-0.3,1-0.6,1.5h0.3c0.5,0,0.7,0,0.9-0.1L96.7,107.3L96.7,107.3z M100.8,107.8
			c0.2,0.5,0.3,1,0.3,1.5c0,0.7-0.3,1.2-0.8,1.3c-0.3,0.1-0.6,0.1-1.1,0.1c-0.2,0-0.3,0-0.5,0c0-0.6-0.1-0.8-0.2-1.2
			c0.3,0,0.5,0.1,0.8,0.1c0.5,0,0.7-0.1,0.7-0.5c0-0.5-0.1-0.9-0.3-1.4c-0.2-0.5-0.5-0.8-1.1-1.4c0.6-1.2,0.9-1.9,1.2-2.8h-1.5v7.3
			c0,0.6,0,0.9,0.1,1.3H97c0-0.3,0.1-0.8,0.1-1.2v-7.6c0-0.3,0-0.6,0-0.9c0.3,0,0.5,0,0.9,0h1.9c0.4,0,0.6,0,0.8-0.1l0.5,0.7
			c-0.1,0.1-0.1,0.2-0.2,0.5c-0.4,1-0.7,1.7-1.2,2.6C100.4,106.9,100.6,107.2,100.8,107.8z" />
                                                <path d="M87,106.2c0.2,1,0.5,1.7,0.9,2.3c0.4-0.8,0.6-1.3,0.9-2.3H87z" />
                                                <rect x="76.4" y="105.4" width="1.6" height="0.7" />
                                                <rect x="73.9" y={107} width="1.5" height="0.7" />
                                                <rect x="81.9" y="103.6" width="1.2" height={1} />
                                                <rect x="81.9" y="105.5" width="1.2" height="0.9" />
                                                <rect x="81.9" y="107.4" width="1.2" height={1} />
                                                <rect x={93} y="108.9" width="2.1" height="1.5" />
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M52.6,49.1v15.1c0,2-1.1,2.3-2.4,2.3c-1.2,0-2.4-0.3-2.4-2.3V49.1h-6.3v14.6c0,4.3,2.7,8.6,8.7,8.6
				s8.7-4.3,8.7-8.6V49.1H52.6z" />
                                                    <path d="M74.4,72.3V55.8c0-1.9,1-1.9,1.5-1.9s1.5,0,1.5,1.9v16.5h5.5V56.6c0-3.6-2.2-7.5-7-7.5
				c-1.8,0-3.7,1.1-4.3,2.4c-0.6-1.3-2.5-2.4-4.3-2.4c-4.8,0-7,3.9-7,7.5v15.7h5.5V55.8c0-1.9,1-1.9,1.5-1.9s1.5,0,1.5,1.9v16.5
				H74.4z" />
                                                    <path d="M98.3,72.3V55.8c0-1.9,1-1.9,1.5-1.9s1.5,0,1.5,1.9v16.5h5.5V56.6c0-3.6-2.2-7.5-7-7.5
				c-1.8,0-3.7,1.1-4.3,2.4c-0.6-1.3-2.5-2.4-4.3-2.4c-4.8,0-7,3.9-7,7.5v15.7h5.5V55.8c0-1.9,1-1.9,1.5-1.9s1.5,0,1.5,1.9v16.5
				H98.3z" />
                                                    <path d="M119.3,49.1v15.1c0,2-1.1,2.3-2.4,2.3c-1.2,0-2.4-0.3-2.4-2.3V49.1h-6.3v14.6c0,4.3,2.7,8.6,8.7,8.6
				c0.9,0,1.7-0.1,2.4-0.3v11c0,2-1.7,2.9-2.9,2.9c-2,0-2.8-0.7-3.8-3s-1.1-7.9-1.1-7.9v-1.6H31.2c0,2.3,2.5,4.2,5.6,4.2
				c2.3,0,4.4-1.1,5.2-2.6h57.5c3,0.2,6.2,3.5,8.1,6.8c1.5,2.8,4.2,7.5,9.4,7.5c6,0,8.7-4.3,8.7-8.6v-17V49.1H119.3z" />
                                                    <path d="M71.6,47.5v-1.6H41.7l0,0H39l0,0h-4.8v1.6h4l-6.9,14.9l-6-14.9h5.4v-1.6h-6l0,0h-6.4l0,0h-1.7v1.6H19l9,22.3
				l-5.5,11.9h-8.1c0,3,2.3,5.5,5.1,5.5c2.2,0,3.8-1.5,4.8-3.6l0,0L41,47.5H71.6z" />
                                                </g>
                                                <g>
                                                    <path d="M37.6,84.7h-2.2c-0.1,1.7-0.1,2.8,0,3.2c0,0.1,0.1,0.2,0.1,0.4c-0.4,0.2-0.8,0.3-1.2,0.3
				c-0.5,0-0.7-0.2-0.8-0.5c0-0.2,0-0.5,0-0.8l0.1-0.8c0.1-3,0.1-4.8,0-5.6c0-0.2-0.1-0.4-0.3-0.6c0.4-0.3,0.9-0.5,1.4-0.5
				c0.4,0,0.7,0.2,0.7,0.5c0,0.2,0,1.3,0,3.3h2.2c0-1.3,0-2.2-0.1-2.6c0-0.2-0.1-0.5-0.3-0.7c0.4-0.3,0.9-0.5,1.4-0.5
				c0.4,0,0.7,0.2,0.7,0.5c0,0.2,0,0.7,0,1.4l-0.1,4.6c0,0.9,0,1.4,0,1.7c0,0.1,0.1,0.2,0.1,0.4c-0.4,0.2-0.8,0.3-1.2,0.3
				c-0.5,0-0.7-0.2-0.8-0.5c0-0.2,0-0.5,0-0.8l0.1-0.8C37.6,85.9,37.6,85.4,37.6,84.7z" />
                                                    <path d="M43.3,85.8c-0.4,0.9-0.7,1.4-0.9,1.4S42,87,41.9,86.5c-0.1-0.7-0.1-1.6,0-2.5c0.2-0.9,0.4-1.4,0.9-1.4
				c0.4,0,0.8,0.3,1.3,0.8C44,84,43.7,84.9,43.3,85.8z M44.3,87.4c0.1,0.8,0.6,1.2,1.3,1.2c0.4,0,0.7-0.1,1-0.4
				c-0.2-0.4-0.4-0.8-0.5-1.3c-0.1-0.7-0.2-1.6-0.2-2.7s0.1-2,0.3-2.5c-0.4-0.2-0.8-0.3-1.2-0.3c-0.3,0-0.6,0.1-0.7,0.3
				c-0.4-0.2-0.9-0.4-1.4-0.4c-1,0-1.8,0.6-2.3,1.8c-0.5,1.2-0.6,2.4-0.4,3.6c0.2,1.3,0.8,1.9,1.7,1.9s1.6-0.9,2.3-2.8
				C44.2,86.5,44.2,87,44.3,87.4z" />
                                                    <path d="M48,88.7c0-0.4-0.1-1-0.2-1.7c-0.1-0.5-0.2-1-0.3-1.6s-0.2-1.2-0.4-1.8c-0.2-0.7-0.4-1.2-0.7-1.7
				c0.4-0.3,0.8-0.4,1.1-0.4c0.5,0,0.8,0.4,1,1.1l0.2,0.8c0.1,0.6,0.3,1.4,0.5,2.5c0.8-1.6,1.1-2.9,0.9-3.9c0-0.1,0-0.1,0-0.2
				c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.4,0.9,1.2c0.1,0.3,0.1,0.7,0.2,1.2c0.1,0.6,0.2,1.5,0.4,2.6c0.7-2,1-3.4,0.9-4.2
				c-0.1-0.3-0.1-0.5-0.3-0.7c0.4-0.2,0.8-0.3,1.1-0.3c0.4,0,0.6,0.2,0.6,0.5c0,0.2-0.2,1.2-0.6,2.8c-0.5,1.6-0.9,2.6-1.2,3.1
				s-0.8,0.8-1.6,0.9c0-0.3-0.1-0.5-0.1-0.6c0-0.2-0.2-0.8-0.3-1.7c-0.1-0.3-0.1-0.7-0.2-0.9c-0.1-0.3-0.1-0.6-0.1-0.8
				c-0.3,1.3-0.6,2.3-1,3C49.4,88.2,48.8,88.6,48,88.7z" />
                                                    <path d="M58.2,85.8c-0.4,0.9-0.7,1.4-0.9,1.4s-0.4-0.2-0.5-0.7c-0.1-0.7-0.1-1.6,0-2.5c0.2-0.9,0.5-1.4,0.9-1.4
				s0.8,0.3,1.3,0.8C58.9,84,58.6,84.9,58.2,85.8z M59.2,87.4c0.1,0.8,0.6,1.2,1.3,1.2c0.4,0,0.7-0.1,0.9-0.4
				c-0.2-0.4-0.4-0.8-0.5-1.3c-0.1-0.7-0.2-1.6-0.2-2.7s0.1-2,0.3-2.5c-0.4-0.2-0.8-0.3-1.2-0.3c-0.3,0-0.6,0.1-0.7,0.3
				c-0.4-0.2-0.9-0.4-1.4-0.4c-1,0-1.8,0.6-2.3,1.8c-0.5,1.2-0.6,2.4-0.4,3.6c0.2,1.3,0.8,1.9,1.7,1.9s1.6-0.9,2.3-2.8
				C59.1,86.5,59.1,87,59.2,87.4z" />
                                                    <path d="M61.9,81.7c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.8,0.2,0.9,0.6c0,0.2,0,0.4,0,0.7c-0.2,2.2-0.2,3.7,0,4.5
				c0.1,0.4,0.2,0.7,0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.7,0-1.1-0.3-1.2-0.9c-0.1-0.5-0.1-1.4,0-2.6s0-2.1-0.1-2.6
				C62.1,82.2,62.1,82,61.9,81.7z M64,80.4c-0.3,0.2-0.6,0.3-1,0.3s-0.7-0.1-0.8-0.4c0-0.1,0-0.2,0-0.4c0-0.2,0-0.3,0-0.4
				s0-0.1-0.1-0.2c0.4-0.2,0.8-0.2,1.1-0.2c0.5,0,0.8,0.2,0.8,0.6C64.1,79.8,64,80.1,64,80.4z" />
                                                    <path d="M65.1,81.7c0.4-0.2,0.9-0.3,1.2-0.3c0.5,0,0.8,0.2,0.9,0.6c0,0.2,0,0.4,0,0.7c-0.2,2.2-0.2,3.7,0,4.5
				c0.1,0.4,0.2,0.7,0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.7,0-1.1-0.3-1.2-0.9c-0.1-0.5-0.1-1.4-0.1-2.6c0.1-1.2,0.1-2.1,0-2.6
				C65.3,82.2,65.3,82,65.1,81.7z M67.2,80.4c-0.3,0.2-0.7,0.3-0.9,0.3c-0.5,0-0.7-0.1-0.8-0.4c0-0.1,0-0.2,0-0.4c0-0.2,0-0.3,0-0.4
				s0-0.1-0.1-0.2c0.4-0.2,0.8-0.2,1.1-0.2c0.5,0,0.8,0.2,0.8,0.6C67.3,79.8,67.3,80.1,67.2,80.4z" />
                                                    <path d="M71.3,85.8c-0.4,0.9-0.7,1.4-0.9,1.4c-0.2,0-0.4-0.2-0.5-0.7c-0.1-0.7-0.1-1.6,0-2.5
				c0.2-0.9,0.5-1.4,0.9-1.4s0.8,0.3,1.3,0.8C72,84,71.7,84.9,71.3,85.8z M72.3,87.4c0.1,0.8,0.6,1.2,1.3,1.2c0.4,0,0.7-0.1,1-0.4
				c-0.2-0.4-0.4-0.8-0.5-1.3c-0.1-0.7-0.2-1.6-0.2-2.7s0.1-2,0.3-2.5c-0.4-0.2-0.8-0.3-1.2-0.3c-0.3,0-0.6,0.1-0.7,0.3
				c-0.4-0.2-0.9-0.4-1.4-0.4c-1,0-1.8,0.6-2.3,1.8c-0.5,1.2-0.6,2.4-0.4,3.6c0.2,1.3,0.8,1.9,1.7,1.9c0.9,0,1.6-0.9,2.3-2.8
				C72.2,86.5,72.2,87,72.3,87.4z" />
                                                    <path d="M77,84.2c0.6-1.8,1.3-2.8,2.3-2.8c0.7,0,1.1,0.4,1.3,1.1c0.1,0.5,0.1,1.3,0.1,2.3c-0.1,1,0,1.8,0,2.3
				c0.1,0.4,0.2,0.8,0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.6,0-1-0.3-1.1-0.9c-0.1-0.5-0.1-1-0.1-1.7c0-1.7,0-2.6,0-2.8
				c0-0.2-0.1-0.3-0.2-0.3c-0.2,0-0.6,0.7-1.1,2s-0.7,2.4-0.6,3.1c0,0.1,0.1,0.3,0.2,0.5c-0.3,0.1-0.7,0.1-1,0.1
				c-0.6,0-0.9-0.2-1-0.6c0-0.2-0.1-0.5,0-0.8l0.1-0.8c0.1-2,0.1-3.4,0-4.1c0-0.2-0.1-0.4-0.2-0.5c0.4-0.2,0.8-0.2,1.1-0.2
				c0.5,0,0.8,0.2,0.9,0.6C77.1,82.4,77.1,83.1,77,84.2z" />
                                                    <path d="M85.3,80.5c0.5,0,0.9,0.3,1,0.9c0.2,1.2-0.4,1.9-1.8,2.1v-2.9C84.8,80.5,85.1,80.5,85.3,80.5z M88,81.1
				c-0.2-1-1.1-1.5-2.6-1.5c-1.3,0-2.3,0.2-3,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.9,0.2,3.2-0.1,6.7c0,0.2,0,0.4,0,0.5
				c0.1,0.3,0.3,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1c0.2,0,0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.5,0s0.4,0,0.5,0c1.1,0,2-0.3,2.7-0.9
				c0.6-0.6,0.9-1.3,0.8-2.1c-0.2-0.9-0.9-1.5-2.1-1.9C87.6,83,88.2,82.2,88,81.1z M84.5,84.5c0.3,0,0.5-0.1,0.9-0.2
				c0.7,0.2,1.1,0.7,1.3,1.5c0.1,0.5,0,1-0.2,1.3c-0.2,0.4-0.5,0.6-0.9,0.6c-0.3,0-0.6-0.1-1.1-0.2L84.5,84.5z" />
                                                    <path d="M92,80.5c0.5,0,0.9,0.3,1,0.9c0.2,1.2-0.4,1.9-1.8,2.1v-2.9C91.5,80.5,91.8,80.5,92,80.5z M94.7,81.1
				c-0.2-1-1-1.5-2.6-1.5c-1.3,0-2.3,0.2-3,0.7c0.2,0.2,0.2,0.4,0.3,0.6c0.2,0.9,0.2,3.2-0.1,6.7c0,0.2,0,0.4,0,0.5
				c0.1,0.3,0.3,0.5,0.7,0.5c0.1,0,0.2,0,0.3-0.1c0.2,0,0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.5,0s0.4,0,0.5,0c1.1,0,2-0.3,2.7-0.9
				c0.6-0.6,0.9-1.3,0.8-2.1C95,84.7,94.3,84,93,83.7C94.3,83,94.9,82.2,94.7,81.1z M91.2,84.5c0.3,0,0.5-0.1,0.9-0.2
				c0.7,0.2,1.1,0.7,1.3,1.5c0.1,0.5,0,1-0.2,1.3c-0.2,0.4-0.5,0.6-0.9,0.6c-0.3,0-0.6-0.1-1.1-0.2L91.2,84.5z" />
                                                    <path d="M97.8,86.3c-0.2-1.1-0.2-2.3,0-3.7c0.2-1.4,0.5-2,1.2-2c0.5,0,0.8,0.5,1,1.5c0.2,1.1,0.2,2.3,0,3.7
				c-0.2,1.4-0.6,2-1.2,2C98.3,87.8,98,87.3,97.8,86.3z M101.7,85.7c0.4-1.2,0.5-2.3,0.3-3.4c-0.3-1.8-1.2-2.7-2.6-2.7
				c-1.2,0-2.2,0.7-2.9,2.1c-0.7,1.4-0.9,2.9-0.6,4.4c0.3,1.8,1.2,2.7,2.6,2.7c0.1,0,0.3,0,0.5,0c1.5,1,2.5,1.6,3,1.6
				s0.9-0.4,1.3-1.4c-0.2,0-0.4,0-0.6,0c-1,0-1.9-0.2-2.8-0.6C100.7,87.7,101.3,86.9,101.7,85.7z" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 日本堂 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[4], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" x={0} width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <g>
                                                <path d="M14.8,71.6h0.3V60.2h-2.6v-0.7h6.1v0.7h-2.6V78h-1L3.7,60.6H3.4V77h2.7v0.7H0V77h2.7V60.2H0v-0.7h7
				L14.8,71.6z" />
                                                <path d="M31.5,77.7h-8.9V77h2.7V60.2h-2.7v-0.7h8.9v0.7h-2.7V77h2.7V77.7z" />
                                                <path d="M44.8,77.7h-8.9V77h2.7V60.2h-2.7v-0.7h8.9v0.7H42v7.7h7.4v-7.7h-2.7v-0.7h8.9v0.7h-2.7V77h2.7v0.7h-8.9V77
				h2.7v-8.4H42V77h2.7V77.7z" />
                                                <path d="M58.8,68.6c0-2.7,0.8-5,2.4-6.8c1.6-1.8,3.6-2.7,6-2.7s4.4,0.9,6,2.7c1.6,1.8,2.4,4.1,2.4,6.8
				c0,2.7-0.8,5-2.4,6.8c-1.6,1.8-3.6,2.7-6,2.7c-2.4,0-4.4-0.9-6-2.7C59.6,73.6,58.8,71.4,58.8,68.6z M62.7,70.9c0,2.4,0.4,4,1,5.1
				c0.7,1,1.8,1.5,3.4,1.5c1.6,0,2.7-0.5,3.4-1.5c0.7-1,1-2.7,1-5.1v-4.5c0-2.4-0.3-4-1-5.1c-0.7-1-1.8-1.5-3.4-1.5
				c-1.6,0-2.7,0.5-3.4,1.5c-0.7,1-1,2.7-1,5.1V70.9z" />
                                                <path d="M93.5,71.6h0.3V60.2h-2.6v-0.7h6.1v0.7h-2.6V78h-1L82.3,60.6h-0.2V77h2.7v0.7h-6.1V77h2.7V60.2h-2.7v-0.7h7
				L93.5,71.6z" />
                                                <path d="M101.2,77.7V77h2.7V60.2h-2.7v-0.7h8.3c2.8,0,5.1,0.8,6.8,2.5c1.7,1.7,2.5,3.9,2.5,6.6c0,2.7-0.8,5-2.5,6.6
				c-1.7,1.7-3.9,2.5-6.8,2.5H101.2z M107.4,77h0.7c1.4,0,2.5-0.1,3.2-0.2c0.7-0.1,1.2-0.3,1.6-0.7c0.7-0.5,1.2-1.3,1.6-2.3
				c0.3-1,0.5-2.4,0.5-4v-2.5c0-1.6-0.2-3-0.5-4c-0.3-1-0.9-1.8-1.6-2.3c-0.4-0.3-1-0.5-1.6-0.7c-0.7-0.1-1.7-0.2-3.2-0.2h-0.7V77z" />
                                                <path d="M123.3,68.6c0-2.7,0.8-5,2.4-6.8c1.6-1.8,3.6-2.7,6-2.7s4.4,0.9,6,2.7c1.6,1.8,2.4,4.1,2.4,6.8
				c0,2.7-0.8,5-2.4,6.8s-3.6,2.7-6,2.7c-2.4,0-4.4-0.9-6-2.7C124.1,73.6,123.3,71.4,123.3,68.6z M127.2,70.9c0,2.4,0.4,4,1,5.1
				c0.7,1,1.8,1.5,3.4,1.5c1.6,0,2.7-0.5,3.4-1.5c0.7-1,1-2.7,1-5.1v-4.5c0-2.4-0.3-4-1-5.1c-0.7-1-1.8-1.5-3.4-1.5
				c-1.6,0-2.7,0.5-3.4,1.5c-0.7,1-1,2.7-1,5.1V70.9z" />
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M42.5,87.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4,0.1,0.6,0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4s0.1-0.3,0.1-0.4c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.4-0.3
					c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.4
					c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.1,0.3-0.2,0.6-0.3c0.2-0.1,0.5-0.1,0.8-0.1
					c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6h-0.5c0-0.2,0-0.4-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.2-0.4,0.3
					c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.2
					c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.3,0.4s0.1,0.4,0.1,0.6
					c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.8,0.4-1.4,0.4c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5
					C42,87.7,42,87.5,42,87.2H42.5z" />
                                                    <path d="M49,88.8v-5.4h0.5v5.4H49z" />
                                                    <path d="M53.5,84.1L53.5,84.1l0,4.7h-0.5v-5.4h0.6l3.1,4.7h0v-4.7h0.5v5.4h-0.6L53.5,84.1z" />
                                                    <path d="M64.2,85c-0.1-0.5-0.3-0.8-0.6-1c-0.3-0.2-0.7-0.3-1.1-0.3c-0.3,0-0.6,0.1-0.8,0.2
					c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.5,0.3,0.8
					c0.1,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.9,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.5
					c0.1-0.2,0.2-0.4,0.2-0.6h0.5c-0.1,0.3-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1.1
					c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,1-0.2c0.3,0,0.5,0,0.8,0.1
					c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.5,0.2,0.7H64.2z" />
                                                    <path d="M67.8,88.8v-5.4h3.6v0.4h-3.1v1.9H71v0.4h-2.8v2.1h3.1v0.4H67.8z" />
                                                    <path d="M77.8,84.9h-1.4v-0.3c0.2,0,0.4,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3
					c0.1-0.1,0.1-0.3,0.2-0.5h0.4v5.3h-0.5V84.9z" />
                                                    <path d="M82.6,87.6c0.1,0.3,0.2,0.5,0.3,0.6c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.6-0.1,0.7-0.2
					c0.2-0.2,0.3-0.3,0.4-0.6c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3,0.1-0.5,0.1-0.8l0,0c-0.2,0.3-0.4,0.5-0.6,0.6C84.3,86.9,84,87,83.7,87
					c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.5,0.1-0.7
					c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.4,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.6
					c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.7,0.1,1c0,0.4,0,0.8-0.1,1.2c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.4,0.4-0.6,0.5
					c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.2s-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.3-0.2-0.6H82.6z
					 M83.8,83.9c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.2,0,0.3,0,0.5-0.1
					c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.4-0.1-0.7-0.3-0.9S84.1,84,83.8,83.9z" />
                                                    <path d="M88.7,87.4c0,0.4,0.2,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.9,0.3c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.2,0.4-0.3
					c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
					c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4l-0.4-0.1l0.4-2.7h2.6V84h-2.2l-0.3,1.8
					c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0.1
					c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.5
					c-0.2,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.4-0.5
					c-0.1-0.2-0.2-0.4-0.2-0.7H88.7z" />
                                                    <path d="M94.6,85.3c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.5-0.4s0.4-0.1,0.7-0.1
					c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6
					c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.3-0.4,0.4s-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.3
					c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3h2.9v0.4h-3.4
					c0-0.3,0-0.5,0.1-0.7c0-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.5-0.3
					c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.1-0.2-0.2-0.3
					c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
					s-0.1,0.3-0.1,0.5H94.6z" />
                                                </g>
                                                <g>
                                                    <line x1="30.5" y1="86.5" x2="37.1" y2="86.5" />
                                                    <line x1="102.9" y1="86.5" x2="109.4" y2="86.5" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* シュピール・オアゼ */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[13], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
										<g>
											<path d="M133.9,79.2c0-0.2-0.1-0.4-0.2-0.5c0,0,0,0,0-0.1c0,0,0,0,0,0l0-0.2c0,0,0-0.2-0.1-0.3
		c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0,0,0,0c0-0.2-0.1-0.4-0.2-0.5c0-0.1,0-0.1,0-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1
		c0-0.2-0.1-0.4-0.3-0.5c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3v0c0-0.1,0-0.4,0-0.4l-0.1-0.3
		c0,0,0,0.1,0,0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1
		c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2
		c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.2-0.1-0.3
		c-0.1-0.2-0.2-0.3-0.4-0.4c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.2-0.1-0.3l0,0c0-0.1,0-0.3-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.1
		c0,0,0,0,0.1,0.1c-0.1-0.1-0.2-0.3-0.2-0.3c-0.2-0.2-0.3-0.2-0.5-0.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.2
		c0,0-0.1-0.1-0.1-0.1c0-0.2-0.1-0.3-0.2-0.4c0,0,0,0-0.1,0c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1-0.1-0.2-0.2-0.3
		l-0.4-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
		c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.1-0.5,0c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.1-0.5,0-0.7,0.2c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0
		c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3
		c-0.2-0.1-0.5-0.2-0.5-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c0,0-0.3,0.1-0.3,0.1c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.2,0.1-0.3,0.1h0c0,0-0.1,0.1-0.3,0.2c-0.1,0-0.1,0-0.1,0c0,0-0.2,0.1-0.2,0.1s0,0,0,0h0c-0.2-0.2-0.4-0.3-0.7-0.2
		c-0.2,0-0.4,0.2-0.5,0.3c-0.1,0-0.2,0-0.2,0c0,0-0.1,0.1-0.3,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
		c-0.2-0.1-0.4-0.2-0.7-0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.2-0.3,0.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1
		c-0.2,0-0.3,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0-0.3,0.1
		c-0.3,0.2-0.4,0.4-0.4,0.6c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1c0,0-0.1,0.1-0.1,0.1c-0.2-0.1-0.5-0.1-0.8,0.1
		c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.3c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.3-0.1-0.4c-0.2-0.3-0.4-0.4-0.6-0.4c0-0.1-0.1-0.4-0.1-0.4s-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0-0.2,0-0.3,0-0.3
		c-0.1-0.2-0.3-0.3-0.4-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.3-0.2-0.5c-0.1-0.2-0.3-0.2-0.4-0.3
		c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.2-0.5c-0.1-0.2-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.2-0.4-0.3
		c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
		c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.3-0.7-0.3c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.1-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0-0.2-0.1-0.3-0.2-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
		c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.2-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.5-0.2c0,0,0-0.1,0-0.1c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.2-0.2-0.2-0.2
		c0,0,0.1,0,0.1,0.1c-0.1-0.1-0.2-0.3-0.2-0.3c-0.2-0.1-0.4-0.2-0.6-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
		c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0-0.2-0.1-0.5-0.4-0.7c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
		c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.4-0.1-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c0,0,0,0,0,0
		c-0.1-0.1-0.2-0.2-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c0,0,0,0,0,0s0,0-0.1,0
		c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.3,0c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.4-0.4-0.7-0.3c0,0-0.2,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.2
		c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.4,0.3-0.5,0.5c-0.7,0.1-0.8,0.6-0.8,0.8c0,0,0,0,0,0.1c0,0.3,0.2,0.6,0.3,0.7
		c0,0.2,0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2
		c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0.2,0.2,0.4,0.3,0.5c0,0,0.2,0.1,0.2,0.1c0.2,0.1,0.5,0.2,0.6,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
		l0,0c0.1,0.1,0.3,0.1,0.4,0.1c0,0.2,0.1,0.5,0.3,0.6c0,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.2
		c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1
		c0.1,0.1,0.1,0.1,0.1,0.1C95.8,56,96,56,96.2,56c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0.2,0.1,0.4,0.3,0.6
		c0.2,0.2,0.4,0.2,0.5,0.2c0,0,0.1,0,0.1,0.1c0,0.2,0.1,0.4,0.3,0.5C98,58,98.2,58,98.3,58c0,0.1,0.1,0.2,0.2,0.3
		c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0,0.1,0.1,0.1
		c0,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.2
		c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0,0.1,0c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0,0.1,0.1,0.1
		c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.3,0.3,0.5,0.3c0,0,0,0,0,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0.2,0,0.4,0,0.4c0,0,0,0.2,0,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.1,0.1,0.1
		c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.4
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.4c0,0,0.1,0.2,0.1,0.2c0,0,0-0.1,0-0.1l0.3,0.5c0,0,0,0.1,0,0.1
		c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2l0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
		c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.2,0.4
		c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.1,0.4s0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0
		c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.2,0.4v0c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0.3,0.1,0.3
		c0,0,0-0.1,0-0.1c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1l0,0.2l0,0c0,0,0,0.2,0,0.2c0,0,0,0,0,0l0,0.2
		c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.2,0.5c0,0.1-0.1,0.1-0.1,0.1s0,0,0.1,0.1
		c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0,0,0c0,0.2,0.1,0.3,0.1,0.4c-0.2,0.1-0.3,0.3-0.3,0.6v0c0,0.3,0.2,0.5,0.4,0.7
		c-0.1,0.1-0.2,0.3-0.2,0.5l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.3v0c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		c0,0.1-0.1,0.3-0.1,0.3c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2
		c0,0,0,0.2,0.1,0.3v0c0,0,0,0,0,0c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0.1,0.1,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0.1,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.5,0.3,0.6c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.1,0.1,0.2c-0.2,0.1-0.4,0.3-0.4,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3
		c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.5,0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.3,0.3c0,0-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.3,0.2-0.3,0.3
		c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1,0.2-0.1,0.2c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.3c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.1-0.4,0.2-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5
		c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0.2-0.1,0.4c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.1,0.1-0.1,0.1
		s0,0.1-0.1,0.2c-0.1,0-0.3,0.1-0.4,0.2c0,0-0.1,0.2-0.2,0.3c-0.2,0-0.5,0-0.7,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c-0.2,0-0.3,0.1-0.5,0.2
		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.3,0.4-0.3,0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0.1
		c-0.2,0-0.4,0-0.5,0.1c0,0-0.1,0.1-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.1-0.3,0.3-0.3,0.4c-0.2-0.1-0.4-0.1-0.7,0
		c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.4,0.3-0.4,0.5
		c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0.1c0,0-0.1,0.1-0.2,0.1
		c-0.1,0-0.2,0.1-0.2,0.1s-0.1,0.1-0.1,0.2c-0.1,0-0.3,0-0.4,0.1l-0.2,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c0,0,0,0-0.1,0
		c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.4,0.1-0.4,0.1c-0.3,0.1-0.5,0.4-0.5,0.8c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.3-0.1-0.5,0
		c-0.2-0.1-0.3-0.1-0.6,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.3,0c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0
		c-0.3,0.1-0.4,0.3-0.5,0.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4-0.1-0.4-0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.2,0.1-0.3,0.2
		c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.3,0c-0.1,0-0.1,0-0.2,0.1
		c-0.1,0-0.3-0.1-0.4,0c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.3-0.1
		c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.3-0.2-0.5-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c-0.3,0-0.5,0.2-0.6,0.3c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1c-0.2-0.1-0.3-0.2-0.5-0.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2,0.1
		c-0.1-0.1-0.2-0.1-0.4-0.1c0,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0.1c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1-0.1-0.3-0.3-0.6-0.3c-0.2,0-0.4,0.1-0.5,0.2
		c0,0-0.1,0-0.1,0c0,0-0.2,0.1-0.4,0.2c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.2,0-0.3,0-0.5,0.1c-0.2-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1-0.1-0.3-0.2-0.5-0.2c0,0-0.2,0.1-0.4,0.1
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.5-0.3c-0.1,0-0.2,0-0.3,0.1c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.2,0-0.3,0-0.4,0.1c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0-0.4,0-0.6,0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1-0.2-0.3-0.4-0.6-0.4
		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.3-0.2-0.3-0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.3,0-0.5,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3-0.1-0.6,0-0.7,0.2c0,0,0,0,0,0
		c-0.1-0.2-0.2-0.3-0.4-0.3c0,0-0.2,0-0.4,0c0,0-0.1,0-0.1,0c-0.1-0.2-0.2-0.3-0.5-0.4c-0.3-0.1-0.5,0-0.7,0.1
		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.1-0.2-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l0.1,0c0,0-0.2-0.1-0.2-0.1h-0.2c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.2-0.1-0.4-0.1-0.6,0c-0.1-0.3-0.2-0.5-0.5-0.6c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.1-0.5-0.1-0.8,0
		c-0.1-0.1-0.2-0.3-0.4-0.4c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
		c0,0-0.1,0-0.2-0.1c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.2-0.3c0,0,0.3,0.3,0.3,0.3c0,0-0.4-0.5-0.4-0.5c-0.1-0.1-0.3-0.1-0.4-0.1
		c0,0-0.1-0.1-0.1-0.1l-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.1-0.4-0.2-0.5-0.2c0,0,0,0,0,0
		c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0-0.1,0-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c0-0.2-0.1-0.3-0.3-0.5
		c0,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.2c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.3-0.6-0.3-0.8-0.2
		c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.2-0.6-0.2-0.6s-0.2-0.1-0.2-0.2c0,0,0,0,0,0c0-0.2-0.1-0.3-0.1-0.3
		c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.2-0.4c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.4
		c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,0,0,0,0c0-0.1-0.1-0.3-0.1-0.3L40,97.8
		c0,0-0.1-0.1-0.3-0.2c0-0.1,0-0.2-0.1-0.3c0,0-0.1-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4
		c0,0-0.1-0.1-0.3-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c0,0-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.2
		c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c0,0,0,0,0-0.1
		c0-0.1,0-0.2,0-0.3c0,0-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.4-0.4-0.5c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0,0,0-0.1c0-0.2,0.1-0.4,0.1-0.4s-0.1-0.1-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.5c0.2-0.1,0.3-0.3,0.3-0.6c0-0.1,0-0.1,0-0.2
		c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4
		c0-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.1l0,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0-0.1-0.1-0.2c0,0,0,0,0,0s0,0,0,0
		c0.2-0.1,0.5-0.3,0.5-0.7v0c0-0.2-0.1-0.4-0.2-0.5c0,0,0,0,0-0.1v0c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.3,0.2-0.5v0
		c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v0c0-0.2-0.1-0.4-0.3-0.6
		c0.2-0.2,0.3-0.3,0.3-0.6v-0.1c0-0.2-0.1-0.4-0.2-0.5c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.3,0.2-0.4l0,0.1
		c0,0,0.1-0.3,0.1-0.3s0-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.1,0.1-0.2
		c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1c0-0.1,0.1-0.3,0.1-0.3c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0-0.2-0.1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0-0.2c0,0,0,0,0,0
		c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3
		c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.1-0.2
		s0-0.1,0-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2
		c0.1-0.1,0.2-0.2,0.3-0.3v0c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.3,0.3-0.3l0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0-0.1c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.3-0.1-0.4
		c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.3,0.1-0.5c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
		c0.2-0.1,0.4-0.3,0.4-0.3s0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0-0.1,0-0.1
		c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.3c0.2,0,0.3-0.1,0.5-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.4
		c0.1,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l0,0
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.3-0.2
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.2-0.2,0.2-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.3
		c0,0,0.1-0.1,0.1-0.1s0,0,0,0c0,0,0-0.1,0-0.1c0.3,0,0.5,0,0.7-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0,0,0,0,0,0s0,0,0,0
		c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1-0.2,0.2-0.3c0,0,0-0.1,0,0c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.3,0.3-0.5
		c0.2,0,0.3,0,0.5-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0,0,0,0,0,0c0,0,0,0,0-0.1c0.2,0,0.4,0,0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.1,0,0.2-0.1c0.3,0.1,0.5,0.1,0.7-0.1c0,0,0.2-0.3,0.3-0.5c0.2,0,0.3,0,0.5-0.1
		c0.2-0.1,0.3-0.3,0.4-0.5c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0.1-0.1,0.2-0.2,0.3-0.3h0c0.1,0,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.7,0
		c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0s0,0,0,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0.1-0.1,0.3-0.3
		c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.1,0.3-0.2c0.1,0,0.3,0,0.4,0c0.2-0.1,0.3-0.1,0.4-0.3
		c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0c0.4-0.1,0.5-0.4,0.6-0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
		c0.2,0,0.3,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0.2,0.4,0.3,0.7,0.2
		c0.1,0,0.3-0.1,0.3-0.2c0,0,0,0,0,0c0,0,0.2-0.1,0.4-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0.1,0.4,0.1c0,0,0.3-0.1,0.4-0.2
		c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0.1,0c0,0-0.1,0-0.1,0c0,0,0.3,0,0.3,0c0.2,0,0.4-0.1,0.5-0.2
		c0.1,0.2,0.3,0.3,0.7,0.3l0.2,0c0.3,0,0.4-0.1,0.6-0.3c0,0,0,0,0.1,0c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
		c0.2,0,0.3,0,0.4-0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5-0.1c0,0,0.1,0,0.1,0s0,0,0.1,0
		c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1
		c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.5-0.1c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.1,0.1,0.1,0.1s0.1,0,0.1,0
		c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0c0.1,0,0.3,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2,0,0.3-0.1c0.1,0.1,0.3,0.3,0.6,0.3
		c0,0,0,0,0.1,0c0.1,0.1,0.3,0.2,0.5,0.2l0,0c0,0,0.1,0,0.1,0c0.1,0.1,0.3,0.2,0.3,0.2c0.3,0,0.5-0.1,0.7-0.3c0,0,0.1,0,0.1,0.1h0
		c0.1,0.1,0.2,0.1,0.4,0.1l-0.1,0c0,0,0.1,0,0.2,0c0.1,0.1,0.3,0.1,0.3,0.1h0.2c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0.2-0.1,0.4-0.1
		c0.2,0,0.3-0.1,0.5-0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.4,0.2c0.2,0,0.4,0,0.5-0.2C81.9,52,82,52,82,52
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.3-0.2,0.4-0.3
		c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.4-0.2c0,0,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0.4-0.2,0.5-0.4
		c0,0,0.2-0.3,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3v0c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0-0.1-0.1-0.3-0.1-0.4c0.2-0.2,0.3-0.4,0.3-0.6c0,0,0,0,0,0c0,0,0-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
		c0-0.2-0.1-0.4-0.3-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.2c0.1-0.1,0.3-0.3,0.3-0.6c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.2-0.5
		c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.2-0.3-0.4-0.4-0.6-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.2-0.2s-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3,0-0.4,0c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2-0.1-0.4,0-0.6,0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0
		c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0.1c-0.1-0.1-0.2-0.1-0.2-0.1
		c-0.2,0-0.3,0-0.4,0.1c0,0-0.1,0-0.2,0c-0.1-0.2-0.3-0.3-0.6-0.3c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1
		c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.1c0,0,0,0,0,0c-0.2-0.1-0.3-0.2-0.3-0.2L74,42c0,0-0.1,0-0.2,0.1
		c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.6,0.1-0.7,0.3c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2,0.1c-0.2-0.2-0.4-0.3-0.6-0.3h-0.2
		c0,0-0.1,0-0.3,0l-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.2,0-0.3,0.1h0c-0.1-0.1-0.3-0.2-0.6-0.2
		c-0.3,0-0.5,0.1-0.6,0.3c-0.1-0.1-0.3-0.3-0.6-0.3c-0.2,0-0.3,0.1-0.4,0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1
		c0,0-0.2,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0.1
		c-0.1-0.2-0.3-0.7-0.9-0.6c0,0-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.2c-0.2-0.1-0.4-0.2-0.6-0.2l-0.2,0c0,0,0,0,0,0h-0.2
		c-0.2,0-0.4,0.1-0.5,0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.3-0.2-0.6-0.1c-0.2,0-0.3,0.1-0.5,0.2
		c-0.1-0.1-0.3-0.2-0.6-0.1c-0.2,0-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.4,0.2
		c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.3,0
		c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.4-0.1s-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.3,0.1-0.5,0.3-0.6,0.5
		c-0.2-0.1-0.4-0.2-0.7-0.1c-0.2,0.1-0.4,0.2-0.5,0.4c0,0-0.1,0-0.1,0L55,44c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0
		c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.3,0.2-0.4,0.3
		c-0.2-0.1-0.4-0.1-0.7,0c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0-0.2,0
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0-0.3,0-0.5,0.1c0,0-0.2,0.2-0.3,0.3c0,0-0.1,0-0.1,0.1c-0.2-0.1-0.4-0.1-0.7,0
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c0,0-0.1,0-0.1,0.1c0,0-0.2,0.1-0.2,0.1
		c0,0-0.3,0.3-0.2,0.2c-0.2,0.1-0.3,0.3-0.3,0.4c0,0,0,0,0,0c-0.2-0.1-0.5-0.2-0.8,0c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0-0.3,0-0.5,0.1
		c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.1,0.1-0.1,0.2c-0.2-0.1-0.5-0.1-0.8,0.1c-0.2,0.1-0.3,0.3-0.3,0.5c0,0-0.1,0-0.2,0.1
		c-0.2,0-0.4,0-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2,0.1-0.2,0.3-0.2,0.4
		c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.4,0.2L40,52.9l-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.1,0.1-0.1,0.2
		c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.2-0.2,0.4-0.2,0.5c0,0,0,0,0,0
		C38,54.9,38,55,38,55.1c-0.2,0-0.5,0-0.7,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
		c0,0,0,0-0.1,0.1c-0.2,0.2-0.2,0.4-0.2,0.5c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.2c0,0-0.1,0.2-0.1,0.3c-0.2,0-0.4,0.1-0.5,0.3
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.2,0.2l-0.2,0.5c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.1,0.3
		c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
		c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.3-0.3,0.3C33,61,33,61.1,33,61.2c-0.2,0.1-0.3,0.1-0.4,0.3
		c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.3-0.4,0.3s0,0-0.1,0.1c-0.2,0.1-0.3,0.2-0.3,0.2
		c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.1l0,0c0,0-0.1,0.4-0.1,0.5c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3-0.2-0.5-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.2-0.2-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.3-0.2-0.3c-0.2-0.1-0.3-0.1-0.5-0.1
		c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.1-0.5-0.1
		c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.2,0-0.4,0c-0.1-0.1-0.2-0.1-0.3-0.2
		c0,0-0.4-0.1-0.3-0.1c-0.2,0-0.4,0-0.5,0c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.5-0.3c0,0-0.1,0-0.2,0
		c-0.1-0.1-0.3-0.2-0.5-0.3c-0.3,0-0.5,0-0.6,0.2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.5,0.1-0.6,0.3h0c0,0-0.1,0-0.2,0.1
		c-0.1-0.2-0.3-0.3-0.7-0.3c-0.2,0-0.3,0.1-0.5,0.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
		c0,0,0,0-0.1,0c-0.2,0-0.3,0-0.3,0c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0-0.1,0.1-0.2,0.1
		c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.2,0.1-0.3,0.2c-0.2-0.1-0.4-0.1-0.6,0l-0.2,0.2c0,0-0.1,0.2-0.3,0.3c-0.2,0-0.3,0-0.5,0.1
		c0,0,0,0.1-0.1,0.1c-0.1,0-0.3,0-0.4,0.1c-0.2,0-0.4,0.1-0.4,0.1c-0.2,0.2-0.3,0.4-0.3,0.7c-0.3,0-0.5,0-0.7,0.2
		c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.2-0.2,0.3-0.2,0.5
		c-0.1,0.1-0.1,0.1-0.1,0.2c-0.2,0-0.3,0.1-0.5,0.3c-0.2,0.2-0.2,0.4-0.2,0.5c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1
		c-0.1,0.1-0.3,0.2-0.3,0.2l-0.2,0.2C10,65.8,10,65.9,10,66.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
		c-0.1,0-0.3,0.1-0.5,0.3C9.3,66.7,9.1,66.7,9,67c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0.1-0.1,0.1s-0.1,0.4-0.1,0.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
		c-0.2,0-0.4,0.2-0.5,0.4c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0-0.1,0.1
		c0,0-0.1,0.3-0.1,0.3s0,0.2,0,0.3c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0,0,0,0c-0.1,0.1-0.2,0.3-0.2,0.3
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2h0l0,0l-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.1,0.4c-0.2,0.1-0.5,0.3-0.5,0.6
		c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.2,0.5c-0.2,0.1-0.4,0.3-0.4,0.6c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.2,0.5c0,0,0,0.1,0,0.1
		c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.2,0.5c0,0.1-0.1,0.2-0.1,0.3v0c0,0.1,0,0.1,0.1,0.2c-0.2,0.1-0.3,0.3-0.3,0.6v0
		c0,0.3,0.1,0.4,0.3,0.6c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0,0.1,0,0.2c0.1,0.3,0.2,0.5,0.5,0.6
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0
		c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0-0.1,0,0,0,0c0,0.1,0,0.3,0,0.3s0.2,0.3,0.3,0.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.3,0.4,0.4c0,0,0,0.1,0,0.1c0.1,0.2,0.3,0.3,0.5,0.4c0,0,0,0,0,0.1
		c0,0,0.1,0.1,0.2,0.2c0,0.1,0,0.2,0,0.2s0,0,0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1
		c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.3,0.4,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.3
		c0.1,0.1,0.2,0.2,0.4,0.3c0,0.1,0,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1v0c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.3,0.5,0.3c0,0,0,0,0,0
		c0,0.2,0,0.3,0.2,0.5c0,0,0.2,0.1,0.4,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
		c0,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.3,0.6,0.3c0,0,0,0,0,0s0.1,0.1,0.3,0.2c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1
		c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.7,0.2c0,0.1,0.1,0.1,0.1,0.2
		c0,0,0.3,0.2,0.4,0.2c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0,0.2,0.1,0.4,0.3,0.6
		c0,0.1,0.1,0.3,0.3,0.4c0,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1
		c0,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.3,0.3,0.4,0.4,0.5l0.2,0.1l0,0c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0.1,0.2,0.1l0.4,0.1c0.3,0,0.5-0.1,0.7-0.3
		c0.1,0,0.3-0.1,0.4-0.2c0,0,0.3-0.3,0.3-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.1c0,0,0-0.3,0-0.2
		c0,0,0-0.1,0-0.2c0-0.2,0-0.6,0-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.3-0.1-0.4
		c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.2-0.1-0.4-0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
		c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.3-0.2-0.4-0.3c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.5
		c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.3c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.4V88l-0.2-0.3c0,0,0,0,0,0
		c0,0-0.1-0.1-0.1-0.1v0c0-0.1,0-0.2,0-0.2c0,0.1,0,0,0,0c0-0.1,0-0.3,0-0.3l0-0.2c-0.1-0.3-0.3-0.4-0.5-0.5c0-0.1,0-0.1,0-0.2
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1-0.1-0.1v0c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1-0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2L16,83.6c0,0-0.1-0.2-0.2-0.3
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.1,0-0.2l0,0c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2,0-0.2c0-0.3-0.2-0.4-0.3-0.5c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.4
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3v0c0-0.2-0.1-0.4-0.2-0.5
		c0-0.1,0.1-0.2,0.1-0.3v0c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0-0.1c0-0.2,0-0.3-0.1-0.4v0c0-0.1,0-0.3-0.1-0.4
		c0.1-0.2,0.2-0.3,0.2-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.2l0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
		c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3-0.1-0.4c0.1-0.1,0.2-0.2,0.2-0.4v0c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.1,0-0.2,0-0.2c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.2
		c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1-0.4,0.1-0.5
		c0,0,0-0.1,0-0.1l0,0c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0
		c0.3,0,0.5,0,0.8-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1-0.1
		c0.2-0.1,0.4-0.2,0.4-0.2c0,0,0-0.1,0.1-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1,0,0.2,0,0.3-0.1
		c0,0,0.1-0.2,0.2-0.3c0.1,0,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0c-0.1,0,0.3-0.1,0.3-0.1c0,0,0.1-0.1,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2,0.1,0.4,0.1,0.6,0l0.2-0.1c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.5,0c0,0,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0.1,0,0.2,0.1,0.4,0c0,0,0.1-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.4-0.2c0,0,0.1,0,0.1,0
		c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0h0c0.1,0.1,0.3,0.2,0.6,0.2c0,0,0.2,0,0.2,0h0
		c0,0,0.2-0.1,0.4-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.2c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.2,0
		c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
		c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0L29,67.6c-0.1,0.1-0.1,0.2-0.1,0.4
		c0,0.1,0,0.2,0.1,0.3c-0.2,0-0.5,0.1-0.6,0.5c-0.1,0.1-0.1,0.2-0.1,0.4c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0.2-0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c-0.2,0-0.5,0.2-0.6,0.5c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.2-0.5,0.5c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.2,0,0.3
		c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0,0,0,0c-0.1,0.1-0.2,0.3-0.2,0.3c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.3,0.1,0.4
		c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.4c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.2c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.3-0.2,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0,0,0.2,0.1,0.3v0c0,0.1,0,0.2,0.1,0.2
		c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.5
		c-0.1,0.1-0.1,0.4-0.1,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2L24.8,82v0.1c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.4-0.1,0.4v0c0,0.4,0.2,0.6,0.3,0.7c0,0,0,0,0,0.1c-0.2,0.1-0.4,0.3-0.4,0.6l0,0.1c0,0,0,0,0-0.1
		c0,0.1,0,0.3,0,0.3v0c0,0.3,0.1,0.4,0.2,0.6c0,0.1,0,0.2,0,0.3v0c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.4,0.7v0
		c0,0.3,0.1,0.5,0.3,0.6c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1l0,0
		c-0.1,0.1-0.1,0.2-0.1,0.4c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.2,0.5c-0.1,0.2-0.1,0.3-0.1,0.3l-0.1,0.2c0,0.1,0,0.2,0.1,0.3
		c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.3,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.2,0.4c0,0,0,0,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2C25.1,91.8,25,92,25,92.2c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.3,0.5c-0.1,0.1-0.1,0.2-0.1,0.4
		c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.2,0.4c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
		c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.2,0.4
		c0,0.1,0,0.1,0,0.2c0,0,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.4
		s0.4,0.4,0.5,0.6c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.3,0.3,0.4
		c0,0.2,0,0.4,0,0.4c0.1,0.2,0.2,0.3,0.3,0.3c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3
		c0,0,0.1,0.2,0.2,0.3c0,0,0-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0.1,0.3,0.3,0.4,0.5,0.4
		c0,0.1,0,0.3,0,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0,0,0.2,0.1,0.3,0.2c0,0,0,0.1,0.1,0.1
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.3,0,0.3
		c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0,0.2,0,0.3,0.1,0.5c0.1,0.2,0.3,0.3,0.5,0.3c0,0,0,0,0,0.1
		c0,0.2,0.1,0.3,0.2,0.5c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0.4,0.3,0.5,0.3c0,0.2,0.1,0.3,0.2,0.5
		c0.1,0.2,0.3,0.2,0.5,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.2,0.4,0.3c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1l0.1,0.1
		c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.4,0.3,0.6,0.3c0,0,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.5,0.2,0.5l0.1,0.2
		c0,0,0.1,0.1,0.3,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.3,0.3l0.1,0.2c0,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0
		c0,0,0.1,0.1,0.1,0.1c0,0,0,0-0.1-0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.5,0.2,0.7,0.2
		c0,0,0,0,0,0s0,0,0.1,0c0,0.2,0.1,0.4,0.3,0.5c0,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0,0.2,0.1,0.4,0.3,0.5
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.4,0.1,0.5,0.1
		c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.1c0,0,0,0,0,0
		c0,0,0,0-0.1,0c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0.1,0.2,0.3,0.4,0.4c0.3,0.1,0.5,0.1,0.7,0c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.3,0.3,0.3,0.3c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.3,0.4,0.4c0.3,0.1,0.5,0.1,0.7,0c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.3,0.4
		c0.1,0.1,0.3,0.3,0.3,0.3s0.2,0,0.4,0c0,0,0.1,0,0.1,0l0,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0.2,0.3,0.3,0.3,0.3l0.2,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2,0,0.4,0
		c0.1,0,0.2,0,0.3,0c0.1,0.2,0.2,0.4,0.5,0.4c0.2,0.1,0.4,0.1,0.6,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.3,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.2,0,0.4-0.1
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0,0.6-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0
		c0.1,0,0.3,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.5,0.4c0.3,0.1,0.5,0,0.7-0.2h0.1c0.1,0.1,0.2,0.3,0.5,0.3c0.1,0,0.2,0,0.4,0
		c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0.2,0,0.4-0.1c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.2,0,0.3,0
		c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.4-0.1
		c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0,0.3,0,0.4,0c0.1,0.2,0.3,0.3,0.6,0.4c0,0,0.1,0,0.1,0l0.2,0h0c0.1,0.1,0.2,0.1,0.4,0.2
		c0,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.2,0,0.3,0
		c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0,0.5-0.1c0.1,0.1,0.3,0.2,0.6,0.3c0.3,0,0.4-0.1,0.6-0.2
		c0.1,0,0.2,0.1,0.3,0.1c0,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0.1,0.3,0.3,0.6,0.3l0.2-0.1c0,0,0.1,0,0.2-0.1c0.1,0.1,0.2,0.1,0.2,0.1c0.4,0,0.6-0.2,0.7-0.4h0c0.2,0.3,0.4,0.4,0.8,0.4
		c0.2,0,0.4-0.1,0.5-0.2c0.1,0.1,0.3,0.2,0.6,0.2c0.3,0,0.4-0.1,0.6-0.3h0.1c0,0,0.1,0,0.1,0c0.1,0.1,0.3,0.2,0.5,0.1
		c0.2,0,0.4-0.1,0.5-0.3c0.1,0.1,0.3,0.2,0.5,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.5-0.2c0,0,0.1,0,0.1,0
		c0.1,0,0.2,0,0.2,0l0.2,0c0,0,0.1,0,0.1,0c0.1,0.1,0.3,0.2,0.6,0.1c0.2,0,0.4-0.1,0.5-0.3c0,0,0.1,0,0.1-0.1
		c0.2,0.1,0.3,0.2,0.6,0.1c0,0,0.1,0,0.2-0.1c0.1,0,0.3,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0.1,0.4,0c0.2,0,0.4-0.1,0.5-0.3
		c0.1,0,0.2,0.1,0.4,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0.1,0.4,0l0.2-0.1c0.3-0.1,0.4-0.2,0.5-0.3c0.1,0,0.2,0,0.3,0
		c0,0,0.1,0,0.1-0.1c0.1,0.1,0.3,0.1,0.5,0c0,0-0.1,0-0.1,0c0,0,0.3,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.2,0,0.2,0c0.2-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0,0.4,0
		c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.2,0.5-0.4c0.1,0,0.2,0,0.3,0
		c0.1-0.1,0.3-0.1,0.3-0.2c0.1,0,0.2,0,0.2,0c0,0,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0c0.2,0.1,0.4,0.2,0.7,0.1c0.1,0,0.2-0.1,0.3-0.2
		c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.4-0.3c0.2,0.1,0.4,0.1,0.6,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3-0.1
		c0.2-0.1,0.4-0.3,0.5-0.5c0.2,0.1,0.4,0.2,0.7,0c0.2-0.1,0.3-0.2,0.4-0.4c0.1,0,0.2,0,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.3-0.2,0.3-0.3c0.1,0,0.3,0,0.4-0.1c0,0,0.1-0.1,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.4
		c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.3-0.3,0.4-0.4c0.1,0,0.2,0,0.3-0.1
		c0,0,0.1-0.2,0.3-0.3c0.1,0,0.2,0,0.3-0.1c0.2,0.1,0.4,0,0.6-0.1c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2-0.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.3-0.3,0.4-0.5l0.1,0c0,0,0.2-0.2,0.2-0.2c0,0,0,0,0,0
		c0.3,0.1,0.6,0.1,0.8-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.4c0,0,0,0,0,0c0.2,0,0.4-0.1,0.4-0.1
		l0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1s0.1-0.1,0.2-0.3c0,0,0,0,0,0c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.2
		c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.2c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1-0.1,0.2-0.2
		c0.2,0,0.4,0,0.6-0.2c0.2-0.2,0.2-0.3,0.3-0.5c0.2,0,0.3-0.1,0.5-0.2c0,0,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0,0,0,0,0,0
		c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3-0.1,0.5-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.3
		c0.2,0,0.4-0.1,0.6-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.1,0.3-0.1s0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0
		c0.3,0,0.6-0.1,0.7-0.3c0,0,0.1-0.2,0.1-0.4c0,0,0.1-0.1,0.1-0.1c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2
		c0.1,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.1,0.2-0.1l0.1-0.2c0,0,0-0.2,0.1-0.3c0.2-0.1,0.4-0.1,0.5-0.3
		c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.2,0.1-0.3,0.1-0.4c0,0,0,0,0-0.1
		c0.2,0,0.3-0.1,0.5-0.4c0,0,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.2,0.1-0.2s0,0,0,0l0,0c0-0.1,0.1-0.1,0.1-0.2
		c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3c0.2,0,0.3-0.1,0.5-0.3l0.1-0.1c0,0,0,0.1-0.1,0.1
		c0.1-0.1,0.2-0.3,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.1c0.2-0.1,0.4-0.2,0.5-0.4v0c0,0,0-0.1,0-0.1
		c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
		c0.2-0.1,0.5-0.2,0.6-0.5c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.1-0.4c0.2-0.1,0.4-0.2,0.5-0.5c0,0,0-0.2,0-0.3c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0-0.2,0-0.2
		c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.3,0.2-0.3s0-0.2-0.1-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.4
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2-0.1-0.3c0.2-0.1,0.3-0.3,0.4-0.6c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0-0.2,0-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.4c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1
		c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0,0c0.1-0.2,0.1-0.3,0.1-0.3s-0.1-0.3-0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.2c0.1-0.2,0.1-0.4,0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.4c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.3,0.2-0.3v0
		c0-0.2,0-0.3-0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.4v0c0-0.3-0.2-0.5-0.3-0.7c0.1-0.1,0.3-0.3,0.3-0.6v0c0-0.1,0-0.2-0.1-0.3
		c0-0.1,0-0.1,0-0.2v0c0-0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.3,0.1-0.4v0c0-0.2-0.1-0.4-0.3-0.6c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
		c0.1-0.2,0.2-0.5,0.2-0.5c0-0.2,0-0.3-0.1-0.4c0,0,0,0,0,0v0c0-0.1,0-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3v0c0,0-0.1-0.2-0.1-0.3
		c0,0,0,0,0,0c0.1-0.1,0.1-0.3,0.1-0.3c0-0.2-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.2-0.1-0.3-0.1-0.4
		c0-0.2,0-0.1,0-0.1c0,0,0.1-0.6,0.1-0.6c0-0.3-0.1-0.4-0.3-0.6c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.3
		c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.3c0,0,0-0.1,0.1-0.1c0.1,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.1,0.2-0.2
		c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.3-0.2c0,0,0,0,0,0
		c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.2,0.6-0.5c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4-0.1,0.5-0.3c0.2,0,0.4,0.1,0.4,0.1
		c0.1,0,0.3,0,0.3-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.4,0.1
		c0.1,0,0.3,0,0.4-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0,0.4,0c0.1,0.1,0.3,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.3,0.1,0.5,0
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0
		c0.1,0.1,0.2,0.3,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0.1,0.1,0.1
		c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0.2,0.5c0,0,0.3,0.3,0.4,0.3c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.2c0,0,0.2,0.1,0.2,0.1
		c0,0-0.1-0.1-0.2-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0v0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.4,0.1,0.4
		c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.2c0,0.2,0,0.4,0,0.4c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2
		l0.1,0.2c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.2,0.4,0.4,0.5v0c0,0.1-0.2,0.6-0.2,0.6c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
		c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3v0c0,0.2,0.1,0.4,0.2,0.5c0,0.1,0,0.2,0.1,0.3c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.3-0.1,0.3c0,0-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c-0.1,0.1-0.2,0.3-0.2,0.3c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.2,0.5c-0.1,0.1-0.3,0.2-0.3,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3
		c0,0,0,0,0,0.1c0,0,0,0.1,0,0.3c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3
		c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.4c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.4,0.4c0,0,0,0.1,0,0.3c0,0.1-0.1,0.1-0.1,0.1
		l0.1-0.1c0,0-0.2,0.3-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.2,0.1-0.3,0.2-0.3,0.2c-0.1,0.1-0.1,0.3-0.1,0.4
		c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1s0,0,0,0.1c-0.2,0-0.3,0.1-0.5,0.3c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.2-0.2,0.4-0.2,0.5c0,0,0,0,0,0.1c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.3-0.2,0.5
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.5,0.2-0.5,0.2c0,0-0.2,0.1-0.2,0.1c-0.2,0.2-0.3,0.4-0.3,0.6
		c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.2c0,0,0,0,0,0.1c-0.2,0-0.3,0-0.5,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0,0,0-0.1,0
		c0,0-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.5,0.1
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.4,0c0,0,0.1,0,0.1,0c0,0-0.2,0.1-0.2,0.1c0,0,0.1,0,0.1,0c-0.1,0-0.3,0.1-0.3,0.1
		s-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1l0.1,0c0,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.3,0.3-0.4,0.4c-0.2,0-0.3,0-0.5,0
		c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0s-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.4,0.1
		c-0.1,0-0.4,0.2-0.5,0.5c0,0,0,0,0,0c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.1,0,0.1,0,0.2c0,0.2,0.2,0.4,0.3,0.5c0,0.1,0.1,0.1,0.1,0.2
		c0.2,0.4,0.4,0.7,0.8,0.7c0.2,0,0.4,0,0.5-0.1c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
		c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.2-0.1c0.1,0,0.4,0,0.4,0l0.2-0.1
		c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.2,0,0.2,0c0.3-0.1,0.5-0.2,0.6-0.5c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0
		s0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1c0.2,0.2,0.5,0.3,0.8,0.2l0.2-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0.1,0,0.1,0
		c0.1,0,0.2-0.1,0.3-0.2c0.2,0.1,0.4,0.1,0.6,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.5-0.1c0,0,0.2-0.2,0.3-0.3c0,0,0.1-0.1,0.1-0.1
		c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.4-0.4c0.2,0.1,0.5,0,0.7-0.1l0.1-0.1l0,0c0,0,0.1-0.1,0.2-0.3c0,0,0.1-0.1,0.1-0.2
		c0-0.1,0.1-0.1,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.3,0.3-0.5c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0.2,0,0.4,0,0.5-0.2
		c0,0,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.4c0.2,0,0.3-0.1,0.5-0.2c-0.1,0-0.1,0.1-0.1,0.1c0,0,0.2-0.1,0.2-0.1c0,0,0,0-0.1,0.1
		c0.1-0.1,0.3-0.2,0.3-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.2,0.2-0.4c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.2
		c0.2-0.2,0.2-0.4,0.2-0.5c0-0.1,0-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.2c0.2,0,0.4-0.1,0.6-0.3c0.1-0.1,0.1-0.2,0.1-0.3
		c0.2,0,0.3-0.1,0.5-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0.1-0.1,0.1-0.1,0.2-0.2l-0.1,0.2
		c0,0,0.3-0.3,0.3-0.3c0,0,0.1-0.3,0.1-0.3c0,0,0,0.1,0,0.1s0-0.3,0.1-0.5c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.3-0.4
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3
		c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.3-0.4v0c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3-0.1-0.4c0,0,0,0,0-0.1
		c0.2-0.1,0.4-0.3,0.4-0.6c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
		c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		C133.9,79.3,133.9,79.3,133.9,79.2L133.9,79.2z"/>
											<path d="M59.3,70.2c0,0,0.1,0.3,0.1,0.3c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2l0-0.1c0,0,0,0.4,0,0.4
		l0.1,0.3c0,0,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.1,0.4,0.2,0.6,0.2
		c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.2,0.1s0.1,0,0.2,0c0.1,0.1,0.3,0.2,0.3,0.2l0.2,0.1c0,0,0,0,0.1,0
		c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.2,0c0,0,0.3-0.1,0.3-0.1c0.2-0.1,0.3-0.2,0.4-0.4
		c0.2,0,0.4-0.1,0.6-0.3c0,0,0.1-0.2,0.1-0.3c0,0,0.2-0.2,0.2-0.2c0,0,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0-0.2
		c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0-0.2,0-0.4c0,0,0-0.1,0.1-0.1c0.1-0.1,0.3-0.3,0.3-0.3
		s0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
		c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.2,0-0.3c0-0.1,0-0.1,0-0.1
		c0,0,0,0,0-0.1c0.2-0.1,0.3-0.2,0.4-0.5c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3
		c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.2-0.3c0,0,0-0.2,0-0.2s0-0.1,0-0.3c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.2C68,64.1,68,64,68,64
		s0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.3,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0l0-0.2c0,0,0-0.2,0-0.2s0,0,0-0.1
		c0-0.1,0.1-0.2,0.1-0.3v-0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0-0.2-0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0-0.1,0-0.1,0-0.2v-0.1c0,0-0.2-0.4-0.3-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0-0.2-0.1-0.4-0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.3c0-0.1-0.1-0.1-0.1-0.1s-0.1-0.1-0.3-0.1c-0.1-0.4-0.4-0.5-0.7-0.6
		c-0.2,0-0.4,0-0.6,0.2c0,0,0,0,0,0l0,0c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.2,0.2-0.3,0.3
		c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0-0.3,0.1-0.5,0.2c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
		c0,0,0,0.1-0.1,0.3c-0.1,0.1-0.4,0.2-0.4,0.2c0,0-0.1,0.2-0.1,0.2l-0.1,0.2c0,0,0,0.1-0.1,0.3c-0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.2v0
		c0,0,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0-0.1,0.1c-0.3,0.1-0.4,0.2-0.5,0.5c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
		c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.2,0,0.2l0,0c0,0,0,0.1,0,0.2c-0.2,0.1-0.3,0.3-0.3,0.4l-0.1,0.2l0,0.2c0,0,0,0.1,0,0.2c0,0,0,0,0,0
		c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0.1
		c-0.1,0.1-0.2,0.2-0.2,0.3l0,0.2l0,0c0,0,0,0.3,0,0.3c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1
		c0,0,0,0.2,0.1,0.3c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0.1,0,0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.2,0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.1,0.4c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.3-0.2,0.5
		c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3C59.4,69.8,59.3,69.9,59.3,70.2C59.3,70.1,59.3,70.1,59.3,70.2"/>
											<path d="M75.1,70c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0.1,0.1c0,0.2,0,0.3,0.1,0.5l0.2,0.2l0,0l0,0
		l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0
		c0,0,0,0,0,0c0.2,0,0.4,0,0.5-0.1c0,0,0.1,0,0.1,0l0.2,0l0,0c0.3,0.1,0.5,0.1,0.6,0c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1
		l0,0c0,0,0.3-0.2,0.3-0.2c0,0,0.2-0.2,0.2-0.2s0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c0,0,0-0.3,0-0.5c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0-0.2,0-0.3c0-0.1,0-0.1,0-0.1s0,0,0-0.1c0,0,0-0.2,0-0.3c0-0.1,0-0.1,0-0.1
		c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0.1,0,0.1c0,0,0.2-0.4,0.2-0.4c0,0,0-0.3,0-0.3c0,0,0,0,0-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4c0.1-0.1,0.3-0.5,0.3-0.5c0,0,0-0.1,0-0.1c0,0,0,0,0,0
		c0-0.1,0.1-0.2,0.1-0.3l0-0.2v0c0,0,0-0.1-0.1-0.3c0,0,0,0,0-0.1c0.1-0.1,0.1-0.3,0.1-0.3c0,0,0-0.2,0-0.2s0-0.1-0.1-0.3
		c0-0.1,0-0.2,0-0.2l0,0.1c0,0,0-0.1,0-0.2c0.1-0.1,0.1-0.3,0.1-0.3s-0.2-0.4-0.2-0.6l0,0c0-0.1,0.1-0.3,0.1-0.3v0
		c0.1-0.1,0.3-0.3,0.3-0.6l0-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0-0.2,0-0.2c0,0-0.1-0.2-0.1-0.4c0-0.1,0-0.1,0-0.2
		c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.2-0.2-0.4-0.4-0.5c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.4c0-0.1-0.2-0.3-0.2-0.3c0,0-0.3-0.2-0.3-0.2L81,58.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.1
		c0,0-0.3-0.1-0.3-0.1c0,0-0.2-0.1-0.2-0.1c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.3,0
		c-0.1,0-0.4,0.1-0.5,0.3c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.1,0.3-0.1,0.3l0,0c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.3-0.1,0.4C77,59.8,77,59.9,77,59.9s0,0.1,0,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.4l-0.1,0.2c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.2,0,0.2s0,0.1,0,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.3,0.1,0.4c0,0,0,0.1,0,0.1s0,0.1,0,0.3c-0.2,0.1-0.3,0.3-0.4,0.5l0,0.3c0,0,0-0.1,0-0.1c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.5l0,0.2c0,0,0,0.1,0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0s0,0.1,0.1,0.2
		c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0.1,0.4,0.1,0.5C75,69.3,75,69.4,75,69.4v0l0,0.2C75,69.7,75.1,69.9,75.1,70"/>
											<path d="M91.3,71.4c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
		c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.3-0.7-0.2c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.6-0.5c-0.2-0.1-0.4,0-0.6,0.1
		c-0.1,0-0.3-0.1-0.3-0.1c0,0-0.3,0-0.3,0c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.1,0-0.1,0s-0.2,0.1-0.2,0.1l-0.4,0
		c-0.3,0.1-0.4,0.4-0.4,0.7c-0.1,0-0.1,0.1-0.1,0.1l0,0c-0.2,0-0.4,0.1-0.5,0.3c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.3-0.1,0.4
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0.1,0,0.1c0,0,0,0,0,0
		c-0.2,0.1-0.4,0.2-0.4,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1
		c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1
		c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.2c0,0-0.1,0.1-0.1,0.2
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.2c0,0-0.1,0.1-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0.3-0.2,0.5
		c-0.2,0-0.4,0-0.6,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1l0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.3,0.1
		c-0.2,0.1-0.3,0.3-0.3,0.4c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0.1c0,0-0.1,0.1-0.1,0.2
		c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.2,0.3l-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2h0h-0.1l0,0
		c-0.2,0-0.7,0-0.7,0c-0.2,0.1-0.4,0.3-0.4,0.6c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c-0.2,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.3-0.6,0.5
		c0,0,0,0,0,0s-0.1,0.1-0.2,0.1c-0.2,0-0.6,0-0.6,0c0,0-0.2,0.2-0.3,0.3c-0.1,0-0.2,0-0.2,0l-0.2,0.1c-0.1,0-0.1,0-0.1,0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.4,0c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0s-0.5,0.3-0.5,0.3
		c-0.1,0-0.1,0-0.1,0s-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.3,0.1-0.4,0.2
		h0c0,0,0,0,0,0c-0.1,0-0.3-0.1-0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.4-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.2,0
		c0,0-0.2,0-0.3,0.1c-0.1-0.1-0.3-0.2-0.5-0.2c0,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.1,0-0.1,0
		c0,0-0.2,0.1-0.4,0.1c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.2,0.1-0.3,0.1
		c-0.1,0-0.2,0-0.3,0.1c0,0,0,0-0.1,0c-0.1-0.1-0.5-0.2-0.6-0.2c-0.2-0.1-0.3-0.2-0.3-0.2s-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1
		s-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0h0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0.1c0,0-0.1,0-0.1,0s0,0-0.1,0
		c-0.1-0.2-0.3-0.3-0.5-0.3c0,0-0.2,0-0.2,0l0,0h-0.3c-0.1-0.2-0.3-0.3-0.5-0.3c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.2-0.4-0.2
		c0,0-0.2,0-0.2,0s-0.1,0-0.3,0c-0.1,0-0.1-0.1-0.1-0.1s-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.2-0.1L56,80.4c-0.1,0-0.2,0-0.4,0
		c0,0,0,0-0.1,0c0,0,0,0,0,0l-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.1-0.2-0.1s-0.1,0-0.2,0c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.2l-0.4-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0.1
		c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0C51.2,79,51,79,50.8,79c0,0-0.1,0.1-0.2,0.1c-0.2,0-0.3,0-0.3,0
		c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.2h0c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.1-0.3,0.2-0.4,0.3c0,0,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1-0.1,0.2
		c-0.1,0.2-0.2,0.3-0.2,0.3c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.3,0.6c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.3
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0,0.1,0.1,0.2,0.2c0,0.2,0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.3
		c0.1,0.2,0.2,0.3,0.2,0.3l0.2,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0,0,0,0c0,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.2,0.2s0.1,0,0.2,0.1
		c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.4,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0.2,0.2,0.3,0.4,0.4c0,0,0,0,0,0l0.1,0.1
		c0,0,0.2,0,0.4,0c0.1,0,0.2,0,0.2,0s0.1,0,0.1,0c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0,0.5,0c0.1,0.2,0.2,0.3,0.5,0.4
		c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.2,0,0.4,0c0.1,0.1,0.2,0.2,0.4,0.2
		c0,0,0.1,0,0.3-0.1c0.1,0,0.1,0.1,0.1,0.1s0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.2,0.2s0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.2,0.1
		c0.1,0,0.2,0,0.2,0c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1c0.2,0.1,0.4,0.3,0.4,0.3c0,0,0.2,0,0.2,0s0.2,0,0.3-0.1h0
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.2s0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
		c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0.1,0,0.2,0c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0.2,0,0.2,0c0.2,0,0.3,0,0.4-0.1c0.1,0.1,0.3,0.1,0.3,0.1
		c0.2,0,0.3,0,0.4-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5-0.1c0.1,0.1,0.6,0.2,0.6,0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0.1
		c0,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4-0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.3,0c0.1,0,0.2,0,0.3-0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
		c0.2,0.1,0.4,0.2,0.6,0.2l0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0.1,0.3,0c0,0,0.2-0.1,0.3-0.2c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0
		c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
		c0,0,0.2-0.1,0.4-0.2c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3-0.1,0.3-0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.2,0
		c0.2,0,0.3,0.1,0.5,0c0,0,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.2-0.2c0.2,0,0.3,0.1,0.5,0c0,0,0.2-0.1,0.2-0.1s0.1-0.1,0.3-0.3
		c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.3,0l0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0c0,0,0.2-0.1,0.2-0.1l0.2-0.1
		c0,0,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		c0,0,0,0,0.1,0c0,0,0.2-0.1,0.2-0.1s0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.3,0c0,0,0.2-0.2,0.2-0.2s0.2,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.4
		c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.2,0,0.4,0,0.6-0.1c0,0,0.1-0.1,0.2-0.2c0,0,0,0,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1
		l0,0c0.2,0.1,0.5,0,0.7-0.1c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.3-0.4,0.3-0.6c0.1,0,0.2,0,0.2,0
		c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.2-0.4c0.1,0,0.3-0.1,0.4-0.2
		c0,0,0.2-0.1,0.2-0.1c0,0,0,0,0.1-0.1c0.2,0,0.3-0.1,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.3-0.1
		c0.2-0.2,0.3-0.5,0.3-0.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1-0.2,0.1-0.2s0-0.1,0.1-0.2
		c0.1-0.1,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.3-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3-0.1,0.4-0.3
		c0,0,0.2-0.6,0.2-0.6c0.1,0,0.3-0.1,0.4-0.3c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.3-0.3,0.3-0.3s0-0.1,0-0.3c0.1-0.1,0.2-0.1,0.2-0.1
		c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1-0.2,0.1-0.2s0.1-0.2,0.1-0.4
		c0.1-0.1,0.2-0.1,0.2-0.1s0-0.1,0-0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
		c0.1-0.1,0.3-0.3,0.3-0.3v-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0C91,72.1,91,72,91,72
		C91.2,71.8,91.3,71.6,91.3,71.4"/>
											<path d="M43.5,41.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.3-0.3-0.5-0.3c0,0,0,0,0,0
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.2-0.4-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.2l-0.1,0l-0.1-0.1c0,0-0.1,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1
		c0,0-0.2-0.1-0.4-0.1C41,39.2,41,39.1,41,39.1c0,0-0.1-0.1-0.1-0.1s0,0,0,0c0,0-0.2-0.2-0.2-0.2s-0.1,0-0.1-0.1
		c-0.1-0.1-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.2-0.1-0.4-0.3-0.5c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c0,0-0.1,0-0.2-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.2s-0.2-0.1-0.3-0.2c0-0.2-0.1-0.3-0.3-0.4c0,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.2s-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2s-0.2-0.1-0.3-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1-0.1
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
		c0,0-0.2,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1v0c0,0,0,0-0.1,0c0-0.1-0.1-0.1-0.1-0.2C35.1,34.2,35,34,35,34c-0.1-0.1-0.2-0.1-0.3-0.2
		c0-0.1,0-0.1,0-0.1s-0.1,0-0.2-0.1c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.2-0.7-0.1l0,0l0,0c-0.1-0.1-0.1-0.3-0.2-0.3
		c0,0-0.1,0-0.3-0.1c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.2-0.3c0,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0-0.1-0.1-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0
		c0,0-0.1-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1l0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.2-0.2-0.2s-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.3-0.3-0.3c0,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
		c-0.2-0.2-0.4-0.2-0.7-0.2c0,0,0-0.1,0-0.1c0,0-0.2-0.1-0.3-0.1c0,0-0.2-0.1-0.5,0c-0.2-0.1-0.4-0.2-0.4-0.2c0,0,0,0-0.1,0
		c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.3,0.1-0.4,0.2C26,28.8,25.6,29,25.6,29c0,0,0,0,0,0c0,0-0.1,0.1,0,0c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0,0,0,0L25,29.8c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0,0.2l-0.1,0.3
		c0,0,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
		c0,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.5,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0.2,0.1,0.3,0.1,0.5
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.1,0.5
		c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.2,0.1,0.2s0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.2,0.1,0.4,0.1,0.5c0,0.1,0.1,0.1,0.2,0.2
		c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.3,0.3c0,0.2,0.1,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.2,0,0.2
		c0,0.1,0.1,0.2,0.3,0.3c0,0.2,0.1,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0.1,0,0.1l0.2,0.2c0,0,0,0,0,0s0.1,0.1,0.2,0.1
		c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.2
		c0,0,0.1,0.1,0.1,0.1s0,0,0.1,0.1c0,0.1,0,0.1,0.1,0.2c0,0.2,0.1,0.3,0.1,0.3c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.3
		s0.2,0.1,0.3,0.2c0,0.1,0,0.3,0,0.3c0.2,0.3,0.4,0.3,0.6,0.4c0,0.1,0,0.2,0,0.2c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2
		c0,0.2,0,0.3,0.1,0.4l0.2,0.1c0,0,0.1,0.1,0.3,0.2c0,0.1,0.1,0.2,0.1,0.3l0.1,0.1l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
		c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.2,0.1,0.2s0.1,0.1,0.2,0.1
		c0,0.2,0.1,0.4,0.1,0.4s0.1,0.1,0.2,0.2c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3
		c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0.1,0.5,0,0.7-0.1c0.2,0.1,0.3,0.1,0.3,0.1l0,0l0.3,0
		c0.2-0.1,0.4-0.2,0.5-0.5c0,0,0,0,0-0.1h0c0.2,0,0.4,0,0.5-0.1c0,0,0.1-0.1,0.2-0.2c0.2,0,0.4,0,0.6-0.1c0.1,0,0.1-0.1,0.1-0.1
		c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.2-0.3,0.3-0.4c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1-0.1
		c0,0,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.2,0,0.4-0.1,0.6-0.3c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.1,0-0.2
		c0.1-0.1,0.2-0.2,0.3-0.4v-0.3c0.1-0.2,0.2-0.3,0.2-0.3C43.7,41.9,43.6,41.7,43.5,41.5C43.5,41.6,43.5,41.6,43.5,41.5 M29.9,39.4
		L29.9,39.4L29.9,39.4z"/>
											<path d="M108.4,36.3c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0,0,0,0,0,0
		c0,0,0.1-0.2,0.1-0.2s0-0.1,0.1-0.2c0.1-0.1,0.3-0.1,0.4-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.3c0,0,0-0.1,0-0.2
		c0.2-0.1,0.3-0.2,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0.2-0.1,0.3-0.1,0.4-0.3
		c0,0,0.2-0.4,0.2-0.4c0,0,0.1-0.1,0.1-0.1s0,0,0,0c0,0,0,0,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0.1-0.1
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3-0.1,0.5-0.3c0,0,0.2-0.6,0.2-0.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
		s0,0,0,0c0.1-0.1,0.3-0.3,0.3-0.6l-0.1-0.3c0,0-0.1-0.2-0.1-0.3c0,0-0.2-0.3-0.2-0.3c0-0.2,0-0.3,0-0.3s-0.1-0.1-0.1-0.1
		c0-0.1-0.1-0.3-0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c0,0,0,0-0.1-0.1c-0.1-0.1-0.3-0.3-0.3-0.3l-0.3-0.1c0,0,0,0,0,0
		c-0.2-0.1-0.3-0.2-0.3-0.2c-0.2,0-0.4,0-0.5,0.1c-0.2-0.1-0.4-0.1-0.5,0l-0.2,0.1c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2,0s0,0,0,0
		c0,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4,0s-0.2,0.2-0.4,0.3c0,0-0.1,0-0.1,0.1c-0.2,0-0.3-0.1-0.5,0c-0.1,0.1-0.2,0.1-0.3,0.2
		c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3,0-0.5,0c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0-0.1,0
		c-0.1,0-0.2,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.2,0.1-0.3,0.3-0.4,0.6
		c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.7,0.1l-0.1,0.1l0,0c0,0-0.2,0.1-0.2,0.1s0,0-0.1,0.1c-0.2,0-0.4,0-0.6,0.1c0,0-0.2,0.2-0.3,0.3
		c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.2,0.3-0.3,0.4c0,0,0,0,0,0.1c-0.2,0-0.3,0.1-0.5,0.2
		c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1-0.1,0.2
		c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0.1-0.1,0.2c-0.2,0-0.4,0-0.6,0.2
		c-0.2,0.1-0.2,0.3-0.3,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.2,0.4-0.3,0.5c0,0,0,0.1-0.1,0.1
		c-0.1,0-0.3,0.1-0.4,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.2,0.2-0.2,0.2c0,0,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.2
		c-0.1,0.1-0.1,0.1-0.1,0.1c0,0-0.2,0.1-0.2,0.1s0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2l-0.1,0.2c0,0-0.1,0.2-0.1,0.3
		c0,0.1-0.1,0.1-0.1,0.1s0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.2-0.4,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0-0.1,0.1
		c0,0-0.1,0.2-0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.1-0.4,0.3-0.5,0.4l-0.1,0.3c0,0,0,0.2,0.1,0.4c0,0.1,0,0.3,0,0.3c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0,0.4,0.2,0.5,0.3
		c0.1,0.1,0.1,0.1,0.1,0.1s0,0,0.1,0c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0.1,0.3s0.1,0.1,0.2,0.1
		c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0,0.2,0.1,0.3,0.2,0.5l0.2,0.2c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.1
		c0,0.2,0.1,0.3,0.2,0.4c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0
		c0.1,0.2,0.2,0.3,0.4,0.4l0.3,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0s0,0,0.1,0c0.1,0.2,0.2,0.4,0.4,0.4c0,0,0.1,0,0.2,0
		c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.1,0.4,0.1,0.5,0c0,0,0,0,0,0c0.2,0.1,0.3,0.2,0.3,0.2c0.2,0,0.5,0,0.7-0.3c0.1,0,0.1,0,0.2,0
		c0.1,0,0.3-0.1,0.4-0.3c0,0,0,0,0,0s0,0,0.1-0.1c0.4,0,0.7-0.2,0.8-0.4c0.1-0.1,0.1-0.2,0.1-0.4c0.1-0.1,0.2-0.2,0.2-0.2
		c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.1-0.1s0,0,0-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.2,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.5c0,0,0,0,0-0.1
		c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.3
		c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5
		v0c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.2-0.1,0.2-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.1l0.1-0.2
		c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0
		c0,0,0-0.1,0.1-0.3c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.3,0,0.5-0.1,0.6-0.3c0.1-0.1,0.1-0.3,0.1-0.4
		c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.2-0.2C108.3,36.5,108.4,36.4,108.4,36.3"/>
											<path d="M61.9,34.5c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.2-0.3-0.3-0.4c0,0,0-0.1,0-0.1
		c0,0-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.2-0.1-0.2c0,0,0,0.1,0,0.1c0,0-0.1-0.3-0.1-0.3c0,0-0.1-0.1-0.1-0.1
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.4-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.3-0.3-0.6-0.4
		c0-0.2,0-0.4,0-0.4c0,0-0.1-0.2-0.1-0.2c0,0-0.1-0.1-0.3-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
		c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.3-0.3-0.5-0.4c0-0.2-0.1-0.5-0.1-0.5
		c0,0-0.1-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2-0.1-0.4c0,0-0.3-0.3-0.3-0.3c0-0.1,0-0.2,0-0.2s-0.1-0.1-0.1-0.2
		c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.4c-0.1-0.1-0.2-0.3-0.3-0.3c0-0.1,0-0.2-0.1-0.3
		c0,0.1,0,0,0,0c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.2-0.2-0.3-0.4-0.4c0-0.2-0.1-0.3-0.1-0.3l0-0.2l0-0.1l0-0.1c-0.1-0.2-0.2-0.3-0.4-0.4
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.4-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
		c0-0.1,0-0.2-0.1-0.3c0,0-0.1-0.1-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0-0.1-0.1-0.2-0.2
		c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3l-0.1-0.2c0,0,0,0-0.1-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2
		c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.4-0.4c0,0,0,0-0.1-0.1c0-0.1,0-0.2,0-0.2
		c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1-0.1-0.2-0.3-0.4-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.6,0.2h0h0h0c-0.1,0-0.3,0-0.3,0c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0-0.1,0v0
		c-0.3,0-0.5,0.1-0.6,0.3c0,0-0.1,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2
		c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0,0,0l0,0.2c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1,0.1,0.3
		c0,0,0,0.2,0,0.2v0c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.2,0.5
		c0,0,0,0,0,0c-0.1,0.2-0.2,0.3-0.2,0.5v0c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0v0c0,0,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1
		c0,0.2-0.1,0.5-0.1,0.5c0,0.2,0.1,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.2,0.5
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0v0c0,0.1,0,0.3,0,0.3c0,0.2,0.1,0.4,0.2,0.5c0,0.1,0,0.1,0,0.1
		c0,0,0,0.2,0,0.2s0,0.1,0,0.2c0,0.1-0.1,0.3-0.1,0.3s0.1,0.2,0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0.2
		c0,0,0,0.2,0,0.2l0,0l0,0.2c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0.2,0.1,0.3
		c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1
		c0,0.2,0.1,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1l0,0.2l0,0.1c0,0,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3
		c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.3,0.5c-0.1,0.1-0.1,0.2-0.1,0.4v0
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.2c0,0.2-0.1,0.4-0.1,0.4
		c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0.2,0.2,0.3,0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.2,0.4
		c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.3c0,0,0,0,0,0l0.3,0.2l0.3,0.1
		c0.1,0,0.3,0,0.4,0c0.2,0.1,0.4,0.2,0.6,0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0l0.1,0l0.1,0c0,0,0.1-0.1,0.2-0.2
		c0.1,0,0.2,0,0.2,0s0.2-0.1,0.3-0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0
		c0.2,0,0.3,0.1,0.3,0.1c0.2,0,0.3-0.1,0.5-0.3c0.1,0,0.1,0,0.2-0.1c0.2,0.1,0.4,0.2,0.7,0.1c0,0,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1-0.1
		c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.2-0.2c0,0,0.2-0.1,0.2-0.1l0.2-0.2c0,0,0,0,0.1,0c0.2,0,0.3,0,0.3,0s0.1-0.1,0.2-0.2
		c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.3,0,0.4-0.1c0,0,0.2-0.2,0.3-0.3c0.1,0,0.3-0.1,0.4-0.2c0,0,0,0,0-0.1
		c0.2-0.1,0.4-0.2,0.6-0.4c0,0,0.1-0.4,0.1-0.4c0,0,0-0.2,0-0.2c0,0,0,0,0-0.1c0-0.2-0.1-0.4-0.1-0.5C61.9,34.7,61.9,34.6,61.9,34.5
		L61.9,34.5z"/>
											<path d="M84.4,15.1c0,0-0.1-0.1-0.2-0.3c0,0,0,0,0,0c0-0.3-0.1-0.5-0.3-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0-0.3,0-0.4,0c-0.2-0.2-0.4-0.2-0.7-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.2,0-0.2,0.1
		c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.2-0.2,0.3-0.2,0.5v0
		c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1
		c-0.1,0.1-0.3,0.3-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4l0,0c0,0,0,0.1,0,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.3-0.4,0.3c0,0.1,0,0.1,0,0.2
		c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
		c0,0-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.3,0.3-0.3,0.3
		c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0.3c0,0,0,0.2,0,0.4c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.3l-0.1,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
		c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0.2,0,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.4-0.3,0.4c0,0.1,0,0.2,0,0.3
		c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0.2,0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
		c0,0,0,0,0,0c0,0.1-0.1,0.3-0.1,0.3c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1
		c0,0-0.1,0.1-0.1,0.1c0,0.1-0.2,0.4-0.2,0.4c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.2,0,0.2s0,0.2,0,0.3v0
		c-0.1,0.1-0.2,0.3-0.3,0.5c0,0,0,0.1,0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0.1,0.2,0.1,0.4c0,0.1,0,0.1,0.1,0.2
		c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.4,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3
		c0,0.1,0.1,0.2,0.2,0.3c0,0,0.3,0.2,0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0
		c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.4,0,0.6,0c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.3,0,0.4,0
		c0.1,0.1,0.2,0.2,0.2,0.2s0.1,0,0.3,0c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.4,0,0.5-0.1c0.1,0,0.1,0,0.2,0c0,0,0.3,0,0.3,0
		c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0.1,0.2,0.3,0.3,0.6,0.3c0.2,0,0.3,0,0.5-0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.4-0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.3-0.4,0.3-0.6c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0,0,0.1-0.3,0.1-0.3
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2c0.1-0.1,0.1-0.3,0.2-0.4v0c0-0.2,0-0.3-0.1-0.4v0
		c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.3v0c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0v0c0-0.2,0-0.3-0.1-0.5
		c0.1-0.1,0.2-0.3,0.2-0.5v0c0-0.2-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2v0c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0-0.1
		c0.1-0.1,0.1-0.3,0.1-0.3c0,0,0,0,0-0.1c0-0.2-0.1-0.3-0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.3s0-0.1-0.1-0.3c0.1-0.1,0.1-0.2,0.1-0.4
		c0,0,0,0,0-0.1c0-0.2-0.1-0.3-0.1-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0,0-0.1-0.5-0.1-0.6c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0,0,0-0.1
		c0-0.1,0-0.2-0.1-0.3v0c0,0,0-0.1-0.1-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1v-0.1v0c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.1-0.4c0-0.1,0.1-0.1,0.1-0.1
		s0-0.2-0.1-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1v-0.2
		c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1
		c0-0.2-0.1-0.3-0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1v0c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.3-0.1-0.5c0.1-0.2,0.2-0.4,0.2-0.4c0,0,0-0.1,0-0.1
		s0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0,0,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.1-0.4c0.2-0.2,0.3-0.4,0.3-0.6v0
		c0-0.2-0.1-0.4-0.3-0.6C84.5,15.3,84.4,15.2,84.4,15.1"/>
											<path d="M130.9,46.7c0-0.1,0-0.4,0-0.4c0,0-0.2-0.3-0.2-0.3l-0.3-0.3c0,0,0,0,0-0.1c0-0.2-0.1-0.3-0.2-0.5
		c-0.1-0.2-0.2-0.4-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1l0,0c-0.1,0-0.2,0-0.3,0
		c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.1-0.4,0.1c0,0,0,0,0,0
		c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.3-0.1s-0.2,0.1-0.3,0.2
		c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.5,0.1-0.5,0.2c-0.1,0-0.3-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0-0.1,0-0.1,0
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0-0.2,0-0.4,0
		c-0.2,0-0.3,0.1-0.4,0.3c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.3c0,0-0.1,0.1-0.2,0.2
		c-0.2-0.1-0.4-0.1-0.6,0c0,0-0.2,0-0.2,0s-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.4,0c0,0-0.2,0.2-0.4,0.3c0,0-0.1,0-0.1,0.1h-0.1
		c0,0,0,0-0.1,0.1c-0.2,0-0.6,0-0.6,0s-0.1,0-0.1,0.1c-0.1,0-0.3-0.1-0.5,0c0,0-0.3,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4,0c-0.2,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.4,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.3-0.4,0.5c0,0-0.1,0-0.1,0.1
		c-0.1,0-0.3,0-0.4,0.1c0,0-0.1,0.1-0.2,0.2c-0.2-0.1-0.4,0-0.6,0.1l-0.2,0.2c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0-0.5,0.1-0.6,0.3c0,0-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.3-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1
		c-0.1,0.1-0.1,0.3-0.1,0.3c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.2,0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.2,0.1,0.2
		c0,0,0.1,0.2,0.1,0.2s0.1,0.1,0.1,0.2v0.3l0.1,0.2c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3
		c0.1,0.2,0.2,0.3,0.3,0.4c0,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.4
		c0,0.1,0,0.3,0.1,0.4l0.2,0.2c0,0,0,0,0.1,0.1c0,0.1,0,0.3,0.1,0.4c0.2,0.2,0.4,0.3,0.5,0.4c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.5
		c0,0,0.2,0.1,0.4,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
		c0,0.1,0.1,0.2,0.2,0.2c0.2,0.2,0.5,0.2,0.8,0.2c0.1,0.1,0.1,0.1,0.1,0.1s0,0,0,0c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.1,0,0.2,0
		c0.2,0.1,0.3,0.1,0.5,0l0.3-0.1c0,0,0.1,0,0.1,0l0.3-0.1c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.1-0.1,0.2-0.2
		c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.2,0.1-0.2s0-0.1,0.1-0.2c0.1,0,0.3-0.1,0.4-0.2
		c0,0,0.3-0.5,0.3-0.6c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.4-0.1s0-0.1,0.1-0.1c0.1,0,0.3-0.1,0.3-0.1l0.2-0.1c0,0,0.2-0.2,0.2-0.2
		c0,0,0.2-0.2,0.2-0.4c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2-0.1c0,0,0.4-0.2,0.4-0.2c0.2-0.1,0.3-0.3,0.3-0.6
		c0.1,0,0.3,0,0.3,0c0,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2
		c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.3c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0,0,0.3-0.2,0.3-0.2
		c0,0,0.2-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1c0,0,0.2-0.1,0.2-0.1s0.2-0.3,0.3-0.5c0.1,0,0.1,0,0.1,0s0,0,0-0.1
		c0.1,0,0.1,0,0.2-0.1c0.2,0,0.4-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.1,0,0.1,0
		c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.3,0.3-0.5c0.1,0,0.2,0,0.3-0.1
		c0,0,0.1-0.2,0.2-0.3c0,0,0,0,0.1-0.1l0.2-0.1c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0l0.2-0.1c0,0,0,0,0,0c0.2,0.1,0.4,0,0.6-0.1
		l0,0c0,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.2,0.3-0.4c0.1,0,0.1,0,0.1,0s0.1,0,0.1-0.1
		c0.2,0,0.3,0,0.3,0c0.2-0.1,0.3-0.3,0.4-0.4c0.3,0,0.5-0.1,0.6-0.3c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.2-0.3
		c0,0,0-0.3,0-0.5C130.8,47.1,130.8,46.9,130.9,46.7C130.9,46.8,130.9,46.8,130.9,46.7C130.9,46.7,130.9,46.7,130.9,46.7L130.9,46.7
		z"/>
										</g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
					{/* 栗原工業株式会社 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[14], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
										<path class="cls-2" d="M70,4.1C33.7,4.1,4.1,33.7,4.1,70s29.6,65.9,65.9,65.9,65.9-29.6,65.9-65.9S106.3,4.1,70,4.1ZM109.2,105.8l-22-34.5v-.2l23.4-29.7,13.1-6.1c6.5,10,10.3,21.9,10.3,34.7s-5.9,30.6-15.6,41.8l-9.1-5.9ZM103.3,102l-16.2-10.6v-14.8l16.2,25.4ZM87.2,66.6v-14.1l17.6-8.3-17.6,22.4ZM67.5,133.8l-15-65.1-19.5,53.2c-16.2-11.6-26.8-30.6-26.8-52S19.7,24.6,39.6,13.8l12.8,54.9L74.5,6.3c13.3.9,25.5,6,35.3,13.8l-57.4,48.7,31.9-15v35.8l-31.9-20.8,47.3,57.8c-9.2,4.8-19.3,7.3-29.7,7.3-.8,0-1.7,0-2.5,0Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 八達不動産鑑定事務所 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[10], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <g>
                                                <path d="M116.2,31.1c0,1.5-4.4,0.1-10.6-3.5c-7.3-4.3-14.2-10-14.2-12s6.9,1,14.2,5.8
				C111.9,25.4,116.2,29.6,116.2,31.1z" />
                                                <path d="M116.2,40c0,1.5-4.4,0.7-10.6-2.2c-7.3-3.4-14.2-8.3-14.2-10.3s6.9,0.1,14.2,4.1
				C111.9,34.9,116.2,38.5,116.2,40z" />
                                                <path d="M116.2,48.9c0,1.5-4.4,1.2-10.6-0.9c-7.3-2.5-14.2-6.5-14.2-8.5s6.9-0.7,14.2,2.3
				C111.9,44.4,116.2,47.4,116.2,48.9z" />
                                                <path d="M116.2,57.9c0,1.5-4.4,1.7-10.6,0.4c-7.3-1.6-14.2-4.7-14.2-6.7s6.9-1.6,14.2,0.6
				C111.9,53.9,116.2,56.4,116.2,57.9z" />
                                                <path d="M116.2,66.8c0,1.5-4.4,2.3-10.6,1.7c-7.3-0.7-14.2-3-14.2-5c0-2,6.9-2.4,14.2-1.2
				C111.9,63.3,116.2,65.3,116.2,66.8z" />
                                                <path d="M116.2,75.7c0,1.5-4.4,2.8-10.6,3c-7.3,0.2-14.2-1.2-14.2-3.2s6.9-3.3,14.2-3
				C111.9,72.8,116.2,74.2,116.2,75.7z" />
                                                <path d="M116.2,84.7c0,1.5-4.4,3.4-10.6,4.3c-7.3,1.1-14.2,0.5-14.2-1.5s6.9-4.2,14.2-4.7
				C111.9,82.3,116.2,83.2,116.2,84.7z" />
                                                <path d="M116.2,93.6c0,1.5-4.4,3.9-10.6,5.6c-7.3,2-14.2,2.3-14.2,0.3s6.9-5,14.2-6.5
				C111.9,91.8,116.2,92.1,116.2,93.6z" />
                                                <path d="M116.2,102.5c0,1.5-4.4,4.4-10.6,6.9c-7.3,2.9-14.2,4.1-14.2,2s6.9-5.9,14.2-8.2
				C111.9,101.3,116.2,101,116.2,102.5z" />
                                                <path d="M116.2,111.5c0,1.5-4.4,5-10.6,8.2c-7.3,3.8-14.2,5.8-14.2,3.8s6.9-6.7,14.2-10
				C111.9,110.7,116.2,110,116.2,111.5z" />
                                            </g>
                                            <g>
                                                <g>
                                                    <path d="M87.5,15.5c0,2-12.7,9.9-25.1,16c-9.7,4.8-16.2,7-16.2,5.7c0-1.3,6.5-6,16.2-11.4
					C74.8,19,87.5,13.5,87.5,15.5z" />
                                                    <path d="M87.5,27.5c0,2-12.7,8.6-25.1,13.5c-9.7,3.8-16.2,5.3-16.2,4c0-1.3,6.5-5.3,16.2-9.7
					C74.8,29.7,87.5,25.5,87.5,27.5z" />
                                                    <path d="M87.5,39.5c0,2-12.7,7.3-25.1,10.9c-9.7,2.8-16.2,3.7-16.2,2.4s6.5-4.6,16.2-8.1
					C74.8,40.4,87.5,37.5,87.5,39.5z" />
                                                    <path d="M87.5,51.5c0,2-12.7,6-25.1,8.3c-9.7,1.8-16.2,2-16.2,0.7c0-1.3,6.5-4,16.2-6.4
					C74.8,51.1,87.5,49.5,87.5,51.5z" />
                                                    <path d="M87.5,63.5c0,2-12.7,4.7-25.1,5.8c-9.7,0.8-16.2,0.3-16.2-1c0-1.3,6.5-3.3,16.2-4.7
					C74.8,61.7,87.5,61.5,87.5,63.5z" />
                                                    <path d="M87.5,75.5c0,2-12.7,3.4-25.1,3.2c-9.7-0.2-16.2-1.3-16.2-2.6s6.5-2.6,16.2-3.1
					C74.8,72.4,87.5,73.5,87.5,75.5z" />
                                                    <path d="M87.5,87.5c0,2-12.7,2.1-25.1,0.6c-9.7-1.2-16.2-3-16.2-4.3s6.5-2,16.2-1.4C74.8,83.1,87.5,85.5,87.5,87.5z
					" />
                                                    <path d="M87.5,99.5c0,2-12.7,0.8-25.1-2c-9.7-2.2-16.2-4.7-16.2-6c0-1.3,6.5-1.3,16.2,0.3
					C74.8,93.8,87.5,97.5,87.5,99.5z" />
                                                    <path d="M87.5,111.5c0,2-12.7-0.5-25.1-4.5c-9.7-3.2-16.2-6.3-16.2-7.6s6.5-0.6,16.2,1.9
					C74.8,104.5,87.5,109.5,87.5,111.5z" />
                                                    <path d="M87.5,123.5c0,2-12.7-1.8-25.1-7.1c-9.7-4.2-16.2-8-16.2-9.3s6.5,0,16.2,3.6
					C74.8,115.2,87.5,121.5,87.5,123.5z" />
                                                </g>
                                                <g>
                                                    <path d="M43.6,38.6c0,1.2-5.2,4.8-10.6,7.5c-4.6,2.3-7.9,3.2-7.9,2.2s3.3-3.5,7.9-6.1
					C38.4,39.3,43.6,37.4,43.6,38.6z" />
                                                    <path d="M43.6,46.1c0,1.2-5.2,4.3-10.6,6.4c-4.6,1.8-7.9,2.4-7.9,1.4c0-0.9,3.3-3.2,7.9-5.3
					C38.4,46.2,43.6,44.9,43.6,46.1z" />
                                                    <path d="M43.6,53.6c0,1.2-5.2,3.8-10.6,5.3c-4.6,1.3-7.9,1.5-7.9,0.6s3.3-2.8,7.9-4.5
					C38.4,53.2,43.6,52.4,43.6,53.6z" />
                                                    <path d="M43.6,61.1c0,1.2-5.2,3.2-10.6,4.3c-4.6,0.9-7.9,0.7-7.9-0.2s3.3-2.5,7.9-3.7
					C38.4,60.1,43.6,59.9,43.6,61.1z" />
                                                    <path d="M43.6,68.6c0,1.2-5.2,2.7-10.6,3.2c-4.6,0.4-7.9-0.1-7.9-1s3.3-2.2,7.9-2.9C38.4,67.1,43.6,67.3,43.6,68.6z
					" />
                                                    <path d="M43.6,76.1c0,1.2-5.2,2.2-10.6,2.1c-4.6-0.1-7.9-0.9-7.9-1.8s3.3-1.8,7.9-2C38.4,74,43.6,74.8,43.6,76.1z" />
                                                    <path d="M43.6,83.6c0,1.2-5.2,1.6-10.6,1c-4.6-0.6-7.9-1.7-7.9-2.6s3.3-1.5,7.9-1.2C38.4,81,43.6,82.3,43.6,83.6z" />
                                                    <path d="M43.6,91.1c0,1.2-5.2,1.1-10.6-0.1c-4.6-1-7.9-2.5-7.9-3.5s3.3-1.2,7.9-0.4C38.4,88,43.6,89.8,43.6,91.1z" />
                                                    <path d="M43.6,98.6c0,1.2-5.2,0.6-10.6-1.2c-4.6-1.5-7.9-3.3-7.9-4.3s3.3-0.8,7.9,0.4
					C38.4,94.9,43.6,97.3,43.6,98.6z" />
                                                    <path d="M43.6,106c0,1.2-5.2,0-10.6-2.3c-4.6-2-7.9-4.2-7.9-5.1s3.3-0.5,7.9,1.2C38.4,101.9,43.6,104.8,43.6,106z" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* つるまい耳鼻咽喉科 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[0], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path d="M112.3,63.4c-2.6-2.7-5.7-4.9-8-7.6c-3.1-3.7-4.3-6.3-4.7-8.1c-0.2-1.2-0.1-3-0.1-4
								C99.5,24,83.5,8.1,63.9,8.1C48.1,8.1,34.7,18.4,30,32.6c3,2.3,9.1,6.9,11.5,8.3c2.5,1.4,7.6,1,9.2,3.3
								c1.8,2.5-0.2,4.6,0.7,6.4c0.9,1.8,4.3,6.7,8.5,11.6c3.8,4.5,28.2,31.2,42.6,36.2c0.1,0.1,0.1,0.1,0.2,0.1
								C112,96.5,119,88.3,119,78.5C119,72.5,116.4,67.1,112.3,63.4z" />
                                                                <path d="M56.7,64.3C46.4,51.7,30,32.6,30,32.6c-2.6,4.1,0,31,7.3,59.2c4.4,17-3.1,25.2,3.8,36.5
								c5.7,9.3,16.9-1.2,21.1-6.5c4.1-5.1,8.4-13,14.4-9.7c5.3-1.9,10-6.6,10-6.6C79.7,93.6,63.8,73.1,56.7,64.3z" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 城北クリニック */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[2], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <path d="M35,109.8c15.6-7.2,20.5-10.8,35.5-40.3c17-33.3,24.7-42.7,60.8-39.1c-16.8,4.3-16.8,18-30.1,45.7
			C87.2,105.3,67.4,112.3,35,109.8z" />
                                            <path d="M35,109.8c-0.7-8.9-1.7-11.9-13.4-24.5C8.3,71.1,5.1,65.7,13.3,48.7c-0.9,9,5.8,11.4,17,22.8
			C42,83.5,41.9,94.4,35,109.8z" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 中央台診療所 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[11], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <path d="M19.9,57.5c1,0.7,7.1-1.3,7.4-0.7s0.3,2,2.2,1.8c1.9-0.1,21.1-4.2,24.1-5.2c3-1,2.4-3.9,1.8-5.3
			c-0.6-1.4,0.2-3.5-2-3.3c-2.2,0.2-19.3,4-21.5,4.2c-2.2,0.2-3.7,0.7-4.4,2.2c-0.6,1.3,0.1,1.2-0.7,1.8c-0.8,0.6-4.7,1.4-6.2,1.7
			C19,55.1,19,56.7,19.9,57.5z M35.4,52.1c1.3-0.4,13.7-3.4,15.3-3.6c1.7-0.3,3.5,1.6,1.6,2.2c-1.9,0.6-15.4,4.4-17.5,4.3
			C32.6,55,32.9,52.9,35.4,52.1z" />
                                            <path d="M16.3,101.7c3.2-1.6,5.8-6.3,4.5-8.7c-1.3-2.3-3.8-2.5-5.9,1.4c-4.2-2.4-5.3,0.1-5.5,1.9
			C9.1,98.3,14.2,102.7,16.3,101.7z" />
                                            <path d="M40.6,75.3c3.7-0.9,17.9-10.2,23.5-14.1c5.7-3.9-2.5-6.7-5.7-5.6c-3.2,1.2-19.1,8.6-21.1,10.2
			C32.2,70.1,36.9,76.2,40.6,75.3z M40.5,72.9c-1.1,0.8-2.3-0.2-1.2-1.1C40,71.2,41.6,72.1,40.5,72.9z M43.1,72.2
			c-0.8,0.6-2.1-0.4-0.7-1.2C43.1,70.6,43.9,71.6,43.1,72.2z M45.1,71.4c-0.5,0.2-1.9-1.2-0.4-1.6C45.5,69.6,45.6,71.2,45.1,71.4z
			 M59.7,62.1c-0.8,0.5-1.6-0.2-0.9-0.7C59.3,61,60.4,61.6,59.7,62.1z M62.9,59.3c0.6-0.1,0.6,1,0.3,1.1
			C62.7,60.6,61.8,59.6,62.9,59.3z M61.5,57.8c0.3-0.1,1,0.9,0.3,1.1C61.2,59.2,60.9,58,61.5,57.8z M61.3,61.3
			c-0.6,0.4-1.4-0.3-0.5-0.8C61.4,60.1,61.9,60.8,61.3,61.3z M60.4,56.8c0.3-0.2,0.6,0.5,0.3,0.8C60.3,57.9,59.6,57.1,60.4,56.8z
			 M59.6,58.8c0.6-0.2,1.2,0.8,0.5,1.1C59.4,60.1,58.7,59.1,59.6,58.8z M58.6,57.6c0.3-0.2,1,0.3,0.5,0.7
			C58.5,58.7,58,57.9,58.6,57.6z M57.3,59.7c0.7-0.4,1.5,0.2,0.9,0.7C57.5,61,56.6,60.1,57.3,59.7z M56.4,58.5
			c0.3-0.3,1,0.4,0.6,0.7C56.5,59.4,55.8,59,56.4,58.5z M45.1,64.5c1.1-0.8,8.4-4.5,9.6-4.5c1.2,0,2.6,2.2,1.8,3.4
			c-0.8,1.2-8.3,5.8-9.5,5.8C45.6,69.2,43.1,65.9,45.1,64.5z M43.4,67.6c0.5-0.1,1.4,1.2,0.5,1.6C43,69.5,42.5,67.9,43.4,67.6z
			 M42.3,65.9c0.5-0.2,0.9,0.7,0.4,1.1C42.2,67.5,41.2,66.3,42.3,65.9z M41.2,68.7c0.8-0.3,1.7,1.2,0.7,1.5
			C40.9,70.6,39.9,69.1,41.2,68.7z M39.7,66.9c0.5-0.3,1.4,0.4,0.7,1C39.6,68.5,39,67.5,39.7,66.9z M39.2,71.1
			c-0.9,0.7-2.3-0.5-1.2-1.1C39,69.5,40.1,70.4,39.2,71.1z M38,69c-0.6,0.4-1.6-0.3-0.8-1C37.5,67.7,38.6,68.6,38,69z" />
                                            <path d="M98.1,41.9c-1.1-0.8-4-2.4-5.7-3c-4.9-2.7-14.2,0-15.8,1.8c-2.6,2.9-1.8,6.5-0.1,9.3
			c1.8,2.8,12.8,3.7,17.6-2.1c0.5-0.6,0.7-2,0.7-3.5c1,0.4,1.8,1.3,2.6,1.6C97.7,44.9,99.4,42.9,98.1,41.9z M86.2,45.8
			c-6.1,1.4-13.3-0.2-6.9-3.7c1.6-0.9,10-3.5,11.7-1.2C92.7,43.2,89,45.1,86.2,45.8z" />
                                            <path d="M56.7,71.7c-2.8,1.8,0.3,5.5,1.3,5.3c1.1-0.2,3.2-2.2,2.8-3.2c-0.1-0.2-0.3-0.5-0.5-0.8
			c4.6-3,15.6-10.1,19.7-12.8c0.5,0.5,1,0.8,1.4,0.7c2.9-1,1.9-3.1,0.5-4.3c-1.2-1-1.8-0.3-2.2,0.4c-0.4,0.6-0.5,1.3-0.3,1.9
			c-4.1,2.4-15.5,10.4-19.7,13.4C58.8,71.5,57.7,71,56.7,71.7z" />
                                            <path d="M89,63.2c0.7,0.4,1.6,0.6,2.1,0.2c1.2-1-0.2-3.9-1.4-3.4c-1.8,0.8-1.9,1.8-1.3,2.6c-4.2,2-17.5,8.7-21.9,10.9
			c-0.5-0.6-1.3-1-1.9-0.8c-2.6,0.8-2.2,1.8-1.6,3.2c0.6,1.3,2.9,1.1,3.9-0.1c0.3-0.4,0.3-0.8,0.2-1.3c5.4-2.6,20.9-10.7,21.8-11.2
			C88.9,63.3,89,63.2,89,63.2z" />
                                            <path d="M51,31c0.2,1.7,1.7,4,4.6,6.7c2.9,2.7,6.1,6.7,8.7,6.8c2.6,0.1,10-5.6,11.3-8.3c1.4-2.6-8.9-12.5-12.2-14.4
			c-1.3-0.8-4.6-0.7-6.7-1.3c1.6-2,1.6-3.2-0.5-4.8c-3.4-2.7-9.9,4.2-10.6,5.9c-1.4,3.7,0.2,6.5,4.4,4.6C50.4,27.6,50.8,29.8,51,31z
			 M55.2,31.4c0.9-1.1,4.9-4.3,6.2-5.7c1.3-1.4,5.7,3.3,5.5,4.1c-0.2,0.8-6.1,6.1-7.6,6.1C57.7,36.1,53.6,33.2,55.2,31.4z" />
                                            <path d="M40,31.8c-1.3,1.8-0.5,2.7,0.4,3.7c0.9,0.9,2-0.2,2.6-2C43.5,31.7,41.5,29.8,40,31.8z" />
                                            <path d="M45.5,34.5c-1.5,0.3-1.5,0.7-1.8,1.7c-0.3,1-0.2,1.8,1.5,2.7c1.7,0.8,2.3-1,2.7-2
			C48.2,35.8,46.4,34.3,45.5,34.5z" />
                                            <path d="M39.8,37.8c-0.7,0.2-1.5,0.8-2.2,2.3c-0.7,1.5,1.2,2.5,2.3,2.5c1.2,0,2.3-1.3,2.5-2.8
			C42.7,38.3,40.7,37.6,39.8,37.8z" />
                                            <path d="M18.8,80.2c0.6-0.2,2-0.7,3.8-1.3c0.9,2,2.4,4.9,2.7,5.1c0.9,0.6,3.3-0.4,4.7-1.4c0.6-0.4-0.6-3.7-1.4-6
			c1.6-0.7,2.8-1.3,3.1-1.6c0.8-1.1,0.1-4.1-1.1-4c-0.4,0-2,0.5-4,1.2c-0.9-1.8-2.3-3.9-2.7-4.2c-1.2-0.9-3.5,0.5-4.4,1
			c-0.6,0.3,0.4,3,1.3,5.3c-1.5,0.6-2.8,1.1-3.4,1.4C14.9,76.6,17.1,80.7,18.8,80.2z" />
                                            <path d="M32.5,97.9c0.9,1.8,3,3.6,5.9,2.7c0.1,0,0.3-0.1,0.4-0.1c1.4,2.7,3.1,5.5,4.3,5.3c2.1-0.4,14.2-6.5,17.3-7.8
			c3.1-1.3,9.9-2.9,8.9-4.9c-1-2-4.9-11.7-6.5-12c-1.4-0.3-3.5,0.9-5.2,1.2c-1-1.4-2.3-2.9-4.3-2.9c-3,0-22.5,9.6-23.9,10.2
			C26.7,90.5,31.6,96.1,32.5,97.9z" />
                                            <path d="M77.8,97.1c2.1,0.7,8.1-1.2,11.1-2.8c2,1.6,6,2.8,5.8,1.1c-0.2-2-0.4-13.5-1.2-14.3c-0.5-0.6-2.9-2.3-4.3-2.9
			c0,0,0,0,0,0c-0.5-1.7-3.2-3.9-5.8-4.2c-1.7-0.2-10.2,2.8-11.2,4.1c-1.7,2.1-0.8,5,0,7.9C72.7,88.2,74.9,96.2,77.8,97.1z
			 M74.6,78.2c1.6-0.9,6.9-3.4,10-2.1c3.1,1.3,2.8,4.7-0.2,5.8c-2.9,1.1-6.5,2.4-9,1.6C72.6,82.7,72,79.6,74.6,78.2z" />
                                            <path d="M102.1,58.3c-0.4-0.2-0.8-0.7-1.2-1.4c0.5-0.1,0.9-0.3,1-0.6c0.2-0.9-1.1-3.8-1.4-5c-0.3-1.2-2.6,0-3.3,0.4
			c-0.7,0.4,0.2,4.3,0.4,4.9c0.1,0.2,0.5,0.3,1,0.4c0,0.8,0,1.5-0.6,1.7c-1.1,0.3-1.8,2-2.3,4.3c-0.5,2.4,2.8,21.6,5,22.2
			c2.2,0.6,9,0.6,9.4-1.8c0.6-3.5-5-22.9-5.4-23.6C104.4,59.1,102.6,58.6,102.1,58.3z" />
                                            <path d="M84,111.5c3.9,1,13.3,3.8,13.9,2.9c0.2-0.4,0.4-1,0.5-1.7c0.4-1.2,0.5-2.8-0.2-3.4
			c-1.1-0.8-14.1-4.6-15.5-5.4c-1.4-0.8-2.2,0.7-4,0.1c-1.8-0.6-14.3-3.4-16.4-3.7c-2.1-0.3-2.2,2.3-0.9,2.4
			c1.3,0.2,13.2,3.2,14.7,3.6c0.7,0.2,1.5,0.4,2.3,0.7c-0.3,0-0.5,0-0.8,0c-1.9-0.4-14.5-2.2-16.7-2.2c-2.2,0-1.9,2.5-0.6,2.5
			c1.3,0,13.4,2.2,15,2.3C78.1,109.8,82.8,111.1,84,111.5z" />
                                            <path d="M97.9,104.7c0.9,1.7,4.2,2.3,6,1.5c1.8-0.9,13.4-8.6,15-9.7c1.6-1.1,2.7-3.2,1.3-5.9c-0.9-1.7-3.9-3-6.2-1.4
			c-5.1,3.5-12.5,7.9-14.8,9.4C97.5,99.7,97,103,97.9,104.7z M116.4,89.9c1.3,0,2.6,1.2,3,2.6c0.3,1.2-0.2,2.3-1.4,3
			c-1.2,0.8-6.4,4.7-6.9,4.8c0.7-2.1-1.8-5.7-3.5-5.7C111.2,92.5,115.1,89.9,116.4,89.9z" />
                                            <path d="M133.9,50.1c-0.1-5.9-12.1-26.7-14.9-28.3c-2.7-1.6-14.6,17.3-16.4,20.9C100.6,46.6,126.4,51.5,133.9,50.1z" />
                                            <path d="M129.3,95.9c-0.6,0.7-2.8,3.1-5.2,5.6c-0.8-0.4-1.8-0.6-2.5-0.5c-1.7,0.5-15.3,13-17.7,14.5
			c-0.7,0.6-0.8,1.4-0.4,2.2c-1.8,1.2-3.8,2.6-5.1,3.4c-0.6-0.8-1.1-1.5-1.5-1.7c-1.1-0.7-2.6,1-1.1,2.9c0.7,1,2.7,4,4.3,5.2
			c1.7,1.3,3,0.9,2-0.7c-0.2-0.4-0.6-1-1-1.6c1.2-0.9,3.4-2.6,5.5-4.2c0.6,0.4,1.2,0.6,1.6,0.7c1.9,0.6,17.4-15,17.8-17.3
			c0.2-0.8-0.2-1.4-0.8-2c2.4-2.7,5-5.6,5.8-6.6C132.6,94.1,130.5,94.6,129.3,95.9z" />
                                            <path d="M56.5,117.1c1.3-1.6,0.6-5.9-1.2-7.5c-2.5-2.2-4.9-1.4-6.9-0.4c-2,1-2,5.3-1.2,7.1c0.6,1.4,4.1,3.3,7,2.4
			c1.4,1.5,2.9,3.7,2.5,4.7c-0.5,1.6-0.7,5.7-4.5,6.8c-2.1,0.6-5.7-0.6-9-1.2c-3.3-0.6-6.9-4.4-7.4-6.1c-0.1-0.4-0.6-2-1.2-3.4
			c0.1-0.1,0.1-0.1,0.2-0.2c1.5-2.1-0.6-7.1-1.2-9c-0.7-1.8-3.9-5.7-6.1-8.1c0.8-1.1-0.5-3.5-1.4-3.9c-1.2-0.6-3.7-0.1-4,0.7
			c-0.4,0.8-0.3,3.2,1,4.7c1,1.2,2.4,0.4,3.5-0.5c1.2,1.3,2.4,2.8,2.7,3.8c0.7,1.9,3.1,5.9,4,7.8c0.4,0.9,0.3,1.7-0.2,2.4
			c0,0-0.1,0.1-0.1,0.1c-0.6,0.7-1.8,1.1-3.2,1.2c-2.8,0.1-4.9-0.9-7.4-2c-1.8-0.8-2.6-3.4-3.5-5.3c0.5-0.5,0.8-1.2,0.8-1.8
			c-0.1-2.8-1.8-3.1-3.7-3c-1.9,0.1-2.5,2.1-2.3,3.4c0.2,1.3,1.8,2.7,2.5,2.9c0.2,0.1,0.6,0,1-0.2c0.8,2,1.9,4.6,2.9,5.4
			c1.2,0.9,5.5,3,7.4,3.5c1.2,0.3,3.5,0,5.2-0.7c0.1,1.2,0.3,2.5,0.7,3.2c2.9,4.9,5.8,5.4,7.2,6.3c1.3,0.8,6.3,2,7.1,2
			c0.8,0,5.8,0.6,7.9-0.6c1.2-0.7,3.6-5.1,3.5-8.3c0-1.2-1.7-3.7-3.1-5.5C56.1,117.6,56.3,117.4,56.5,117.1z M50.7,112.2
			c0.7-1,3.3-0.7,3.2,1.5c0,1.2-1.5,2-2.2,1.9C50.7,115.3,50,113.2,50.7,112.2z" />
                                            <path d="M85.5,119c-0.5,0.4-1.7,1.4-3.1,2.4c-0.7-0.2-2-0.4-3.4-0.7c0-0.1,0-0.1,0.1-0.2c0.8-2-0.9-5.4-1.2-6.5
			c-0.3-1.1-3.9,0.2-5,0.9c-1.1,0.7-6.3,4.1-7.6,4.8c-0.4,0.2-0.7,0.4-0.9,0.6c-0.7,0.2-1.2,0.4-1.3,0.9c-0.7,2.2-2.5,9.1-2.1,11.9
			c0.5,2.8,6.7,2.1,8.9,2.7c2.3,0.7,9.3,0.3,12-1.1c0.1-0.1,0.2-0.1,0.3-0.2c2.5-0.8,7-3.5,8.4-4.2c1.8-0.8,1-4.4,1.6-6.5
			c0.6-2.1-1.4-5.3-1.9-6.3C90,116.6,86.5,118.2,85.5,119z M76.1,115.3c1.4-0.2,1.4,2.1,1.5,4.4c0,0.3,0,0.5,0,0.8
			c-2.3-0.3-4.6-0.6-5.7-0.3c-0.9,0.2-2.5,0-4.2,0c0.6-0.4,1.1-0.8,1.7-1.2C71,117.9,74.7,115.5,76.1,115.3z M89.2,129.2
			c-0.8,0.9-3.6,2.3-5.8,3.3c0.8-2.9,0.7-7.6-0.1-9.8c1.7-1.4,4.3-3.3,5.4-3.6c1.4-0.3,1.7,2,2,4.3C91,125.6,90.3,127.9,89.2,129.2z
			" />
                                            <path d="M131.9,54.2c-1.4-1.5-5,0.4-5.4,1.1c-1,1.7-0.5,3.3,0.3,4.4c-0.6,1.1-1.5,2.7-2.1,4c-2.5-2.2-5.5-4.5-5.9-4.6
			c-0.1,0-0.1-0.1-0.2-0.1c0.2-0.7,0.3-1.4,0.4-2c0.3-1.4-2.2-3.2-5-4.1c-2.7-0.8-4.5,2.6-4.5,4.3c0,1.4,0.6,2.2,2.5,3.4
			c2,1.2,3.6,1.7,4.9,1.1c0.4-0.2,0.8-0.6,1.1-1.1c0.9,1,2.8,3.3,4.2,5.2c-1.7,1.4-4.3,3.8-4.6,4.7c-0.4,1.3-3.2,5.9-3.5,7.8
			c-0.3,2.4-1.3,6.3-1.2,7.6c0.1,1.1,5.1-4.9,6.4-7.3c1.5-2.6,3.2-4.7,4.5-6.7c-0.4,2.7-1.1,5.9-1.1,6.6c0.2,1.2,4.8,12.9,5,13.9
			c0.5,1.1,3.7-11.4,4-14.3c0.6-2.9-2.7-9.1-3.3-10.6c-0.1-0.3-0.6-0.9-1.3-1.6c0.6-1.5,1.3-3.2,1.8-4.5c1.4,0.4,4.3,0.1,5.2-2
			C134.6,58,133.4,55.7,131.9,54.2z M115.8,59.1c-0.6,0.5-2.7,0.3-3.3-0.2c-0.6-0.5-0.4-2.2-0.2-2.7c0.4-1,2.9-1.2,3.5-0.3
			C116.4,56.8,117.3,57.8,115.8,59.1z M131.1,58.8c-0.9,0.4-3.4,0.1-2.7-2c0.4-1.1,2.1-1.2,2.6-0.5C131.6,57.1,132,58.4,131.1,58.8z
			" />
                                            <path d="M12.5,83.2c0.6-4.7-0.8-10.3-0.7-11.7c0.2-1.4-0.2-6.7-0.3-8.1c-0.1-0.8,1.5-6.1,2.1-10.1
			c1.1,0,2-0.4,2.2-1.3c0.4-1.9-0.2-17.4-0.9-15.6c-1.2,3.1-6,11.4-4.9,14.4c0.1,0.4,0.3,0.7,0.6,1c-1.5,3.4-3.2,8.8-3.4,11.8
			c-0.2,2.2-1.3,5.5-1.4,8.8c-0.1,3.8,0,8.2,0.1,9.5c0.2,2.4,0.8,7.6,3.1,8.8C11.5,92,11.9,87.8,12.5,83.2z" />
                                            <path d="M31.8,40.9c2.9-3.1,1.8-12.6-2.1-13.8c-0.3-0.1-0.8-0.2-1.3-0.2c1.1-2.2,3.1-5,4.5-5.9c1.8-1.1,7.6-6.8,11-8
			c3.5-1.1,7.6-2.6,11.9-3.4c2.5-0.5,9.9,0.7,14.7,1.7c-0.9,2.6-1.9,6.1-2.1,8c-0.1,2.8,10.7,6.5,13.9,7.4c3.3,0.9,7.3,3.5,8,2.6
			c0.8-0.9,7.2-11.6,7.6-13.7c0.6-2.1-0.2-2.7-2-3.8c-1.5-1.9-16.6-8-19.4-8.1c-1.3-0.1-3.4,2-4.5,3.7c-3.6-0.6-8.5-1.2-11.5-1.2
			c-3.1,0-10.3,1.7-11.7,1.9c-8.8,1.6-15.8,9.2-17.7,10.7C29.6,20.3,26,24,25,27.2c-1.9,0.4-3.7,1.3-4.3,2.6c-1.3,2.6-1.9,7,0,10.7
			C22.7,44.5,28.9,44,31.8,40.9z" />
                                            <path d="M95.6,27.1c-0.4,2.4,1.9,3.8,4.2,6.9c2.3,3,8.3-4.5,8.6-7c0.1-0.7-0.2-1.7-0.8-2.8c1.7-1.7,3.6-3.5,4.5-4.6
			c1.6-1.9-1-2.5-2.8-1.9c-0.9,0.4-2.4,2-3.8,3.5c-1.1-1.1-2.3-1.8-3-1.2C101.3,21.3,96,24.7,95.6,27.1z" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* CB教育 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[3], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" x={0} width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <path d="M52.8,97.4c-9.2,9.2-24.1,9.2-33.3,0c-4.4-4.4-6.9-10.3-6.9-16.6c0-6.3,2.4-12.2,6.9-16.6
			c9.2-9.2,24.1-9.2,33.3,0c1.2,1.2,2.8,1.8,4.5,1.8c1.6,0,3.2-0.6,4.5-1.8c2.5-2.5,2.5-6.5,0-8.9c-14.1-14.1-37-14.1-51.1,0
			C3.8,62,0,71.1,0,80.7c0,9.7,3.8,18.7,10.6,25.6c7,7,16.3,10.6,25.6,10.6c9.3,0,18.5-3.5,25.6-10.6c2.5-2.5,2.5-6.5,0-8.9
			C59.2,94.9,55.2,94.9,52.8,97.4z M55.1,57.5c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0
			C54,60.6,54,58.7,55.1,57.5z M12.9,57.5c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2c-1.2,1.2-3.1,1.2-4.2,0
			C11.8,60.6,11.8,58.7,12.9,57.5z M17.2,103.9c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2s3.1-1.2,4.2,0
			C18.3,100.9,18.3,102.8,17.2,103.9z M59.3,103.9c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0
			C60.5,100.9,60.5,102.8,59.3,103.9z" />
                                            <path d="M129.4,55.2c-10.9-10.9-27.1-13.4-40.3-7.4V17.5c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3v42.2v42.2
			c0,0.4,0,0.8,0.1,1.2c0,0,0,0,0,0.1c0.1,0.4,0.2,0.7,0.3,1.1c0,0,0,0.1,0,0.1c0.1,0.4,0.3,0.7,0.5,1c0,0,0,0,0,0.1
			c0.2,0.4,0.5,0.7,0.8,1c7,7,16.3,10.6,25.6,10.6c9.3,0,18.5-3.5,25.6-10.6c6.8-6.8,10.6-15.9,10.6-25.6
			C140,71.1,136.2,62,129.4,55.2z M82.8,104.8c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3c1.6,0,3,1.3,3,3C85.7,103.5,84.4,104.8,82.8,104.8z
			 M82.8,62.6c-1.6,0-3-1.3-3-3s1.3-3,3-3c1.6,0,3,1.3,3,3S84.4,62.6,82.8,62.6z M82.8,20.4c-1.6,0-3-1.3-3-3s1.3-3,3-3
			c1.6,0,3,1.3,3,3S84.4,20.4,82.8,20.4z M89.1,99V62.4c9.2-7.5,22.8-6.9,31.4,1.7c4.4,4.4,6.9,10.3,6.9,16.6
			c0,6.3-2.4,12.2-6.9,16.6C111.9,105.9,98.3,106.5,89.1,99z M127.1,103.9c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2
			c1.2-1.2,3.1-1.2,4.2,0C128.2,100.9,128.2,102.8,127.1,103.9z M127.1,61.7c-1.2,1.2-3.1,1.2-4.2,0c-1.2-1.2-1.2-3.1,0-4.2
			c1.2-1.2,3.1-1.2,4.2,0C128.2,58.7,128.2,60.6,127.1,61.7z" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 大島ランドリー */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[6], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <path d="M101.4,112.1c-1.9,2.6-4,4.4-6.2,5.1c-2.2,0.8-5.5,1.2-9.9,1.2h-5.5c-4.2,0-6.9-0.4-8.2-1.1
		c-1.3-0.7-2-1.8-2.3-3.1c-0.3-1.3-0.4-4.6-0.4-9.8V94.3c0.4,0,0.7,0,1.1,0c11.8,0,21.4-3.5,28.6-10.4c7.3-7,10.9-16,10.9-27.3
		c0-11.6-3.8-20.7-11.4-27.4c-7.6-6.7-17-10.1-28.3-10.1c-11.2,0-20.5,3.4-28,10.2c-7.5,6.8-11.2,15.8-11.2,27
		c0,11.5,3.7,20.7,11.1,27.7c4.8,4.5,10.4,7.5,16.8,9.1v9c0,6.1-0.2,9.9-0.5,11.6c-0.3,1.7-1.1,2.9-2.3,3.7
		c-1.2,0.8-3.3,1.2-6.4,1.2H47v2.5h61.6l0.5-21.3h-2.5C105.1,105.3,103.3,109.5,101.4,112.1z M49.8,82.9c-4.7-5.8-7-14.6-7-26.3
		c0-11.5,2.3-20.2,7-26c4.7-5.9,11.4-8.8,20.1-8.8c8.9,0,15.7,3,20.3,9c4.7,6,7,14.6,7,25.9c0,11.6-2.4,20.3-7.1,26.2
		c-4.7,5.8-11.2,8.8-19.4,8.8c-0.6,0-1.2,0-1.8,0V66.4c0-6,0.2-9.8,0.6-11.4c0.4-1.6,1.1-2.7,2.2-3.3c1.1-0.6,3.4-0.9,7-0.9h3.2
		v-2.5H47v2.5h1.6c3.7,0,6.1,0.3,7.1,1c1.1,0.6,1.8,1.8,2.1,3.5c0.3,1.7,0.5,5.4,0.5,11.2v23C55,88,52.1,85.8,49.8,82.9z" />
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 三輝工業 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[1], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <polygon points="36.4,24.7 12.7,48.9 38.2,48.9 44.1,42.9 103.6,42.9 115,24.7 		" />
                                            <polygon points="103.6,115.3 127.3,91.1 101.8,91.1 95.9,97.1 36.4,97.1 25,115.3 		" />
                                            <polygon points="103.6,60.9 44.1,60.9 38.2,55 12.7,55 36.4,79.1 95.9,79.1 101.8,85 127.3,85 		" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* 広野製作所 */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[5], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <polygon points="36.8,48.5 12.7,24.7 12.7,50.3 18.6,56.1 18.6,56.1 18.6,83.9 12.7,89.7 12.7,115.3 36.8,91.5 
			36.8,48.5 		" />
                                            <polygon points="103.2,48.5 127.3,24.7 127.3,50.3 121.4,56.1 121.4,56.1 121.4,83.9 127.3,89.7 127.3,115.3 
			103.2,91.5 103.2,48.5 		" />
                                            <rect x="42.9" y="60.9" width="54.2" height="18.2" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                    {/* サンキ */}
                    <div className="WorksList__item">
                        {showButton && (
                            <WorksButton className="worksButton" onClick={() => setDetail({ ...detail, show: true, target: works[8], })}>
                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 140 140" style={{ enableBackground: 'new 0 0 140 140' }} xmlSpace="preserve" fill="#879fbe">
                                    <g>
                                        <defs>
                                            <rect id="SVGID_1_" width={140} height={140} />
                                        </defs>
                                        <clipPath id="SVGID_2_">
                                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                                        </clipPath>
                                        <g>
                                            <g>
                                                <rect x="61.4" y="102.4" width="8.7" height="4.7" />
                                                <rect x="66.9" y="93.6" width="8.7" height="4.7" />
                                                <rect x="46.9" y="102.4" width="8.7" height="4.7" />
                                                <rect x="52.3" y="93.6" width="8.7" height="4.7" />
                                                <rect x="37.8" y="93.6" width="8.7" height="4.7" />
                                                <rect x={56} y="111.1" width="8.7" height="4.7" />
                                                <rect x="26.9" y="111.1" width="8.7" height="4.7" />
                                                <rect x="32.3" y="102.4" width="8.7" height="4.7" />
                                                <rect x="41.4" y="111.1" width="8.7" height="4.7" />
                                            </g>
                                            <rect x="69.8" y={33} width="8.7" height="4.7" />
                                            <rect x="64.4" y="41.7" width="8.7" height="4.7" />
                                            <rect x="84.4" y={33} width="8.7" height="4.7" />
                                            <polygon points="42.4,37.6 64,37.6 64,33 40.5,33 24.9,48.8 24.9,56.5 40.5,72.3 97.6,72.3 110.4,85.4 110.4,89.3 
			97.6,102.4 76,102.4 76,107 99.5,107 115.1,91.2 115.1,83.5 99.5,67.7 42.4,67.7 29.6,54.6 29.6,50.7 		" />
                                            <rect x="78.9" y="41.7" width="8.7" height="4.7" />
                                            <polygon points="44.1,41.7 37.2,48.8 37.2,56.5 44.1,63.6 101.2,63.6 122.7,85.4 122.7,89.3 101.2,111.1 70.5,111.1 
			70.5,115.8 103.2,115.8 127.3,91.2 127.3,83.5 103.2,58.9 46.1,58.9 41.8,54.6 41.8,50.7 46.1,46.4 46.1,46.4 58.5,46.4 
			58.5,41.7 		" />
                                            <rect x="93.5" y="41.7" width="8.7" height="4.7" />
                                            <polygon points="38.8,28.9 69.5,28.9 69.5,24.2 36.8,24.2 12.7,48.8 12.7,56.5 36.8,81.1 93.9,81.1 98.2,85.4 
			98.2,89.3 93.9,93.6 81.5,93.6 81.5,98.3 95.9,98.3 102.8,91.2 102.8,83.5 95.9,76.4 38.8,76.4 17.3,54.6 17.3,50.7 		" />
                                            <rect x="75.3" y="24.2" width="8.7" height="4.7" />
                                            <rect x="104.4" y="24.2" width="8.7" height="4.7" />
                                            <rect x="98.9" y={33} width="8.7" height="4.7" />
                                            <rect x="89.8" y="24.2" width="8.7" height="4.7" />
                                        </g>
                                    </g>
                                </svg>
                            </WorksButton>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}