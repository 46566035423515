import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import Button from '../atom/button/button'
import Image from '../image/image'
import './styles.scss'

export default ({ ...props }) => {
    return (
        <div className="WorksDetail">
            <div className="WorksDetail__close">
                <Button onClick={props.closeDetail}>
                    <span>一覧に戻る</span>
                </Button>
            </div>
            <div className="WorksDetail__content">
                <div className="WorksDetail__body">
                    <h3 className="WorksDetail__url">
                        <a href={props.detail.target.url} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLink} className="WorksDetail__fontAwesomIcon" />
                            <span>{props.detail.target.url}</span>
                        </a>
                    </h3>
                    <div className="WorksDetail__companyName">＜{props.detail.target.name}＞</div>
                    <div className="WorksDetail__flexContainer">
                        <div className="WorksDetail__pcArea">
                            <CSSTransition in={props.detail.show} appear={true} timeout={300} classNames="topin-bottomout" unmountOnExit>
                                <Image filename={props.detail.target.src_pc} alt={props.detail.target.name} style={{boxShadow: "2px 3px 7px rgba(0, 0, 0, 0.17)"}} />
                            </CSSTransition>
                        </div>
                        <div className="WorksDetail__spArea">
                            <CSSTransition in={props.detail.show} appear={true} timeout={300} classNames="leftin-rightout" unmountOnExit>
                                <Image filename={props.detail.target.src_sp} alt={props.detail.target.name}  style={{boxShadow: "2px 3px 7px rgba(0, 0, 0, 0.17)"}} />
                            </CSSTransition>
                        </div>
                    </div>
                    <div className="WorksDetail__description">
                        {props.detail.target.description}
                    </div>
                </div>
            </div>
        </div>
    )
}