import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// ページじゃないコンポーネントでもGraphQLが使えるようにuseStaticQueryを使う
export default props => {
    const { allFile } = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxWidth: 1600) {
                                originalName
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <figure>
            <Img 
                fluid={allFile.edges.find(n => n.node.childImageSharp && n.node.childImageSharp.fluid.originalName === props.filename).node.childImageSharp.fluid}
                alt={props.alt}
                style={props.style}
            />
        </figure>
    )
}
